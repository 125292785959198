<div class="invoices">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h2 class="m--0"><strong>Invoices</strong></h2>
  </div>

  <app-skeletons [visibleSection]="'invoices'" *ngIf="loading"></app-skeletons>

  <div class="invoices-table" *ngIf="!loading || !firstLoading" [ngClass]="loading ? 'table-hide' : ''">
    <p-table #dt [value]="dataSource" [columns]="cols" styleClass="p-datatable" [rowHover]="true"
      [responsive]="true"
      [paginator]="true" 
      [rows]="paginate.size"
      [showCurrentPageReport]="true" 
      [rowsPerPageOptions]="[10,25,50,100]"
      [totalRecords]="paginate?.totalPages*paginate?.size"
      currentPageReportTemplate="{{dt.filteredValue?.length || dataSource?.length}} entries"
      (onPage)="pageChange($event)"
      (onSort)="sortChange($event)"
      [first]="skipRecords"
      [lazy]="true"
      [filterDelay]="0"
      [globalFilterFields]="['name', 'domain', 'status', 'invoiceTotal', 'invoiceFeeTotal', 'invoiceDiscountTotal', 'updated', 'created', 'gid', 'id']">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="input-field--search filter-table">
            <section class="table-actions">
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort"
                matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="clearTable(dt)">
                <em class="pi pi-filter-slash"></em>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="dt.exportCSV()">
                <em class="pi pi-file"></em>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf(dt)">
                <em class="pi pi-file-pdf"></em>
              </button>
              <p-dropdown *ngIf="domainsOptions.length > 1" class="domains-filter" [showClear]="true" [(ngModel)]="domainValue" [options]="domainsOptions" placeholder="Domains" (onChange)="domainSearch($event.value)">
              </p-dropdown>
            </section>
            <section class="table-filter">
              <div class="table__icon table__icon--lg">
                <em class="fas fa-search"></em>
              </div>
            </section>
            <input pInputText type="text" [ngModel]="inputSearch"
              (input)="inputFilterSeach($event?.target?.value)" placeholder="Type to search" />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of cols">
            <th [pSortableColumn]="checkSortableColumn(col?.field) ? col?.field : null" [class.is-currency]="col.isCurrency">
              <div class="p-d-flex p-jc-between p-ai-center">
                <p class="table-header">{{col.header | titlecase}}</p>
                <p-sortIcon *ngIf="checkSortableColumn(col?.field)" field="{{col.field}}"></p-sortIcon>
                <!-- <p-columnFilter *ngIf="col.field === 'status'" field="{{col.field}}" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-dropdown #selectedStatus [ngModel]="value" [options]="statusOptions" placeholder="Status">
                      </p-dropdown>
                      <div class="d-flex justify-content-between align-items-center buttons-filter">
                        <p-button label="Clear" styleClass="p-button-outlined" (onClick)="filterTable(undefined)"></p-button>
                        <p-button label="Apply" (onClick)="filterTable(selectedStatus.value)"></p-button>
                      </div>
                  </ng-template>
                </p-columnFilter> -->
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-dataSource>
        <tr class="p-selectable-row">
          <td (click)="selectInvoices(dataSource)" class="td-width-l align-left-table"><span [outerHTML]="dataSource?.name | titlecase | isEmpty"></span></td>
          <td (click)="selectInvoices(dataSource)" *ngIf="isSuperAdmin"><span [outerHTML]="dataSource?.domain | isEmpty"></span></td>
          <td>
            <span (click)="selectInvoices(dataSource)" [outerHTML]="dataSource?.status | titlecase | isEmpty"></span>
            <span *ngIf="dataSource?.retry >= 1" matTooltip="Number of retries: {{dataSource?.retry}}" matTooltipClass="tooltip tooltip--black"> ({{dataSource?.retry}}) <span class="txt--red">*</span></span>
          </td>
          <td (click)="selectInvoices(dataSource)" class="align-right-table" matTooltip="{{invoiceService.getTooltip(dataSource)}}" matTooltipClass="tooltip tooltip--black"><span [outerHTML]="dataSource?.invoiceTotal | currency | isEmpty"></span></td>
          <td (click)="selectInvoices(dataSource)" class="align-right-table"><span [outerHTML]="dataSource?.invoiceDiscountTotal | currency | isEmpty"></span></td>
          <td (click)="selectInvoices(dataSource)" class="align-right-table td-width-s"><span [outerHTML]="dataSource?.invoiceFeeTotal | currency | isEmpty"></span></td>
          <td (click)="selectInvoices(dataSource)"><span [outerHTML]="dataSource?.created  | date: 'MM/dd/yy' | isEmpty"></span></td>
          <td (click)="selectInvoices(dataSource)"><span [outerHTML]="dataSource?.updated  | date: 'MM/dd/yy' | isEmpty"></span></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No invoices found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

</div>
