// dep
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

// app
import {VERIFICATION_EMAIL} from "../constants/firestore/collections";

@Injectable({
  providedIn: "root"
})

export class VerificationEmailService {

  constructor(private afs: AngularFirestore) {}

  /**
   * this method save in firestore entity Verification Email
   * @param string
   */
  // TODO: Unused, remove
  // save(uid: string, gid: string, hash: any) {
  //   return this.afs.collection(`${VERIFICATION_EMAIL}`).add({'key1': uid, 'key2':gid, 'hash': hash});
  // }
  // update(id: string, data: object){
  //   return this.afs.collection(`${VERIFICATION_EMAIL}`).doc(id).set({...data}, {merge: true});
  // }

  /**
   * Get user with hash doc id to verify account
   * @param string
   */
  // TODO: Unused, remove
  // getVerificationToHash(hash: string) {
  //   return this.afs.collection(`${VERIFICATION_EMAIL}`,rf => rf.where('hash', '==', hash)).get();
  // }

  /**
   * Get user with gid and uid
   * @param string
   */
  getVerification(key1: string, key2: string) {
    return this.afs.collection(VERIFICATION_EMAIL, rf => rf.where('key1', '==', key1).where('key2', '==', key2)).get();
  }
}
