
// dep
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { Table } from 'primeng/table';
// import { forkJoin } from 'rxjs';

// app
import User from 'src/app/constants/firestore/user';
import { Sort } from 'src/app/constants/sort';

import { AuthService } from '../../services/auth.service';
import { WhiteLabelService } from '../../services/white-label.service';
import { DomainService } from '../../services/domain.service';
import { AdvancedPageable } from '../../constants/pageable';
import { InvoiceService } from '../../services/invoice.service';
// import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  @ViewChild('dt') table: Table;

  public user: User;
  public resultUsers: User[];
  public domain: string;
  public domains$;
  public sort: Sort;
  public previousPageable: { size: any; page: any };
  public pagination: any;
  public filterField: string;
  public domainValue;
  public loading = true;
  public firstLoading = true
  // public isAllSelected: boolean;
  public paginate: AdvancedPageable = { page: 1, size: 10,  totalPages: 1};
  public skipRecords = 0;
  public dataSource: any;
  public searchDomain: string = null
  public inputSearch: string = null
  public statusOptions = []
  public domainsOptions = []
  public statusFilter = null
  public cols = [
    { header: 'Name & Email', field: 'name' },
    { header: 'Domain', field: 'domain' },
    { header: 'Status', field: 'status' },
    { header: 'Total', field: 'invoiceTotal', isCurrency: true },
    { header: 'Discount', field: 'invoiceDiscountTotal', isCurrency: true },
    { header: 'Map Labs Platform Fee', field: 'invoiceFeeTotal', isCurrency: true },
    { header: 'Created On', field: 'created' },
    { header: 'Updated On', field: 'updated' },
  ];
  public sortableColumns = ['name', 'domain', 'created', 'status', 'updated']
  // public statusSelect = ['pending', 'paid', 'unpaid', 'canceled', 'pastdue', 'refunded'] as const
  // public statusInvoice;
  public isSuperAdmin : boolean

  private _searchTime: any;

  constructor(
    public auth: AuthService,
    public wl: WhiteLabelService,
    public domainService: DomainService,
    public invoiceService: InvoiceService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    // private productService: ProductsService,
  ) {
    this.user = this.auth.session;
    this.isSuperAdmin = this.auth.session.isSuperAdmin;
    this.initSort();
  }

  ngOnInit(): void {
    if (!this.isSuperAdmin) {
      this.cols.splice(1, 1);
    };
    this.getData();
  }

  getData(waitMs=0): void {
    if (this._searchTime){
      clearTimeout(this._searchTime)
    }

    this._searchTime = setTimeout(()=> {
    this.loading = true;
    this.invoiceService.getInvoicesPaginated(this.paginate, this.sort, this.searchDomain, this.inputSearch, this.statusFilter)
    .subscribe(
      res => {
        this.dataSource = [];
        const invoice = res['items'];
        this.paginate.totalPages = res["totalPages"]
        invoice.forEach(el => {
            el['status']  = el?.status.toLowerCase()
            el['name']    = `${el?.user.displayName} - ${el?.user.email}`
            el['updated'] = new Date(el?.updatedAt.$date)
            el['created'] = new Date(el?.createdAt.$date)
            el['id']      = el?._id.$oid
            this.dataSource.push(el);
        });
        
        this.dataSource = this.dataSource.sort((a, b) => b.created - a.created);
        this.loading = false;
        this.firstLoading = false
        this._cdr.detectChanges();
      },
      err => {
        this.loading = false;
        this.firstLoading = false;
      }
      
    );
  }, waitMs)
  }

  pageChange(event: any): void {
    this.skipRecords = event.first
    this.paginate.page = event.first/event.rows + 1
    this.paginate.size = event.rows
    this.getData()
    
  }

  sortChange(event: any): void {
    this.sort = {
      sortBy: event.field,
      direction: event.order === -1 ? "desc": "asc"
    }
    this.getData()
  }

  domainSearch(selectedDomain: string): void{
    this.searchDomain = selectedDomain
    this.getData()
  }

  inputFilterSeach(searchValue: string): void{
    this.inputSearch = searchValue
    this.getData(1000)
  }

  getDomains() {
    this.domainService.getDomains().subscribe(
      res => {
        if (res.domains.length > 0) {
          this.domainsOptions =  this.domainService.getOptionsDomains(res.domains);
          this.getData();
        }
      },
      err => this.loading = false
    );
  }

  initSort(): void {
    this.sort = {
      sortBy: 'created',
      direction: 'desc',
    };
  }

  exportPdf(table) {
    const data = table.filteredValue || this.dataSource;
    const exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    const doc = new jsPDF('p','pt', [window.innerWidth, window.innerHeight]);

    const body = data.map(r => {
      const row = {}
      for(const col of this.cols) 
        row[col.field] = col.isCurrency ? InvoiceService.formatPrice(r[col.field]) : r[col.field]
      return row
    })

    autoTable(doc, { columns : exportColumns, body })
    doc.save("invoices.pdf");
  }

  selectInvoices(data) {
    this._router.navigate(
      [`admin/invoice-details`],
      {
        state: {
          'invoice': {
            'id': data?._id?.$oid,
            'previousPage': 'invoices',
            'domain': this.domainValue,
            'filterField': this.filterField
          }
        }
      }
    )
  }

  filterDomain(table, value) {
    table.filterGlobal(value, 'contains')
  }

  clearTable(table) {
    this.inputSearch = null;
    this.domainValue = null
    table.clear()
  }

  checkSortableColumn(colName: string): boolean{
    return this.sortableColumns.includes(colName)
  }

  filterTable(filterValue: string){
    this.statusFilter=filterValue
    this.getData()
    }

}
