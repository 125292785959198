import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/constants/api-response';
import { Pageable } from 'src/app/constants/pageable';
import GradeLead from "src/app/constants/firestore/grade-leads";
import { WhiteLabelService } from 'src/app/services/white-label.service';
import _ from 'lodash';
import { GradeLeadService } from 'src/app/services/grade-lead.service';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-grade-leads',
  templateUrl: './grade-leads.component.html',
  styleUrls: ['./grade-leads.component.scss']
})
export class GradeLeadsComponent implements OnInit {
  public loading = true;
  public dataSource = [];
  public cols = [
    { header: 'Account Name', field: 'accountName' },
    { header: 'Location Name', field: 'locationName' },
    { header: 'Email', field: 'email' },
    { header: 'Domain', field: 'registrationDomain' },
    { header: 'Created On', field: 'created' },
  ];
  public viewedOptions = ['Read', 'Unread'];
  public viewedValue: string;
  public filterField: string
  public paginate: Pageable = {page: 1, size: 10};
  public previousPageable: { size: any; page: any };
  public pagination: Pagination;
  public domainsOptions = [];
  public totalRecords = 0;
  public filter = { domain: "", viewed: null };
  public domain: string;
  public resultGradeLeads: GradeLead[];
  public sort: {
    sortBy:string,
    direction:string
  };
  // public isAllSelected = false;
  public tableLoading = false;
  public isSuperAdmin;

  constructor(
    public gradeLeadService: GradeLeadService,
    private cdr: ChangeDetectorRef,
    private wlService: WhiteLabelService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = this.auth.session.isSuperAdmin;
    if (!this.isSuperAdmin) {
      this.cols.splice(3, 1);
    }
    this.gradeLeadService.getAllDomains().subscribe((result: Array<any>) => {
      if (!result || result.length === 0) {
        return;
      }
      result.map( async domain => {
        this.resultGradeLeads = Array<GradeLead>();
        await this.gradeLeadService.getAllGradeLeads(domain.domain).forEach(( gL: Array<any>) => {
          if (!gL || gL.length === 0) {
            return;
          }
          let gradeLeads = Array<GradeLead>()
          gL.map( gradeLead => {
            gradeLeads.push( gradeLead )
          })
          this.resultGradeLeads.push( ...gradeLeads )
          this.gradeLeadService.set(gradeLeads)
          for (const index of gradeLeads) {
            index.id = JSON.stringify(index);
          }
          this.domain = this.wlService.baseDomain;
          this.filterByDomain({ value: this.domain });
        })
      })
    });
    this.cdr.detectChanges();
    this.domain = this.wlService.baseDomain;
  }

  filterByDomain({ value }): void {
    this.initPaginator();
    this.initSort();
    if ("all" !== value) {
      // this.isAllSelected = false;
      const domain = ("localhost" === value && "localhost:4200") || value.replace(/\_/g, ".");
      this.filter.domain = domain;
      this.getData(this.filter);
    } else {
      // this.isAllSelected = true;
      this.filter.domain = "";
      const source = this.filterGradeLeads(this.resultGradeLeads)
      this.setData(source);
    }
  }

  filterGradeLeads( gradeLeads ) {
    return _.filter(
      gradeLeads,
      o => {
        if ( this.filter.domain != "" ) {
          if( this.filter.viewed != undefined ) {
            return o.registrationDomain === this.filter.domain && o.viewed === this.filter.viewed
          }
          else {
            return o.registrationDomain === this.filter.domain
          }
        }
        else {
          if( this.filter.viewed != undefined ) {
            return o.viewed === this.filter.viewed
          }
          return o
        }

      }
    );
  }

  getData(filter: { domain: string; viewed: boolean } = null) {
    this.loading = true;
    this.gradeLeadService
      .getGradeLeads(
        this.paginate,
        filter
      )
      .subscribe(async result => {
        this.pagination = result;
        this.getDomains(result.items);
        this.getCreatedOn(result.items);
        this.dataSource = result.items;
        this.loading = false;
      });
  }

  setData(results) {
    const data = _.chunk(results, this.paginate.size);
    const items = data[this.paginate.page - 1];
    const source = this.gradeLeadService.getGradeLeadPaginate(
      results.length,
      this.paginate,
      items
    );
    this.previousPageable = { size: source.per_page, page: source.page };
    this.pagination = source;
    this.getDomains(source.items);
    this.getCreatedOn(source.items);
    this.dataSource = source.items;
    this.tableLoading = false;
    this.loading = false;
  }

  getDomains(results) {
    const domains = results.map(el => el.registrationDomain);
    const domainsSet = [...new   Set(domains)];
    this.domainsOptions = domainsSet.filter(el => el != null && el != undefined);
  }

  getCreatedOn(data) {
    data.forEach(el => {
      if(el.createdAt) {
        el.created = new Date(el.createdAt?.seconds * 1000)
      }
    });
  }

  viewedGradeLead(gradeLead: GradeLead) {
    gradeLead.viewed = true;
    this.gradeLeadService.save(gradeLead);
  }

  loadGradeLead(gradeLead: GradeLead) {
    localStorage.setItem('gradeLead', JSON.stringify(gradeLead));
    window.open(`https://${gradeLead.registrationDomain}/grade`);
  }

  initPaginator(): void {
    this.paginate = { page: 1, size: 10 };
    this.previousPageable = null;
  }

  initSort(): void {
    this.sort = {
      sortBy: "createdAt",
      direction: "desc"
    };
  }

  exportPdf(table) {
    const data = table.filteredValue ? table.filteredValue : this.dataSource;
    const exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    const doc = new jsPDF('p','pt', [window.innerWidth, window.innerHeight]);
    doc['autoTable'](
      exportColumns,
      data,
      {
        columnStyles: {
          0: {columnWidth: 120},
          1: {columnWidth: 120}
      }
    });
    doc.save("grade-leads.pdf");
  }

  clearTable(table) {
    this.filterField = null;
    this.viewedValue = null;
    table.clear();
  }

  filterDomain(table, value) {
    table.filterGlobal((value === 'Read'), 'contains');
  }

  downloadPdf(gradeLead: GradeLead): void {
    this.gradeLeadService.downloadExternalGradePDF(gradeLead.id).subscribe(result => {
      this.downloadFileFromBase64(result.base64_pdf, `${gradeLead.accountName} ${gradeLead.locationName} ${gradeLead.registrationDomain}.pdf`)
    });
  }

  downloadFileFromBase64(base64String: string, fileName: string) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileBlob = new Blob([byteArray], { type: 'application/octet-stream' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(fileBlob);
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
