
// dep
import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { /*StripeCardComponent,*/ StripeService } from 'ngx-stripe'
import { /*StripeCardElementOptions,*/ StripeElementsOptions } from '@stripe/stripe-js'
import { Router } from '@angular/router'
import _ from 'lodash'
import { isDevMode } from '@angular/core'

// app
import { PaymentsService } from '../../../services/payments.service'
import { WhiteLabelService } from "../../../services/white-label.service"
import { ModalService } from "../../../services/modal.service"
import { AlertType } from "../../../components/alert.component"


@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss']
})

export class UpdateCardComponent implements OnInit {

  elements;
  card;
  elementOptions: StripeElementsOptions = {
    // TODO: un-hardcode localization
    locale: 'en'
  };

  stripe: FormGroup
  loading = false
  modalData: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateCardComponent>,
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private paymentService: PaymentsService,
    private wl: WhiteLabelService,
    private modalService: ModalService,
    private router: Router) {
    this.modalData = this.data.data;
  }

  get form() {
    return this.stripe;
  }

  async ngOnInit() {
    this.stripe = this.formBuilder.group({ name: ['', Validators.required] });

    const stripe_maplabs_pubkey = await (isDevMode() ? this.wl.getStripeTestMaplabsPublicKey() : 
                                                       this.wl.getStripeMaplabsPublicKey())

    const stripe_connect_account_id = await this.wl.getStripeConnectAccountId()

    if(stripe_connect_account_id)
      this.stripeService.changeKey(stripe_maplabs_pubkey, { 'stripeAccount': stripe_connect_account_id });

    this.stripeService.elements(this.elementOptions).subscribe(elements => {
      this.elements = elements;

      if (!this.card) {
        this.card = this.elements.create('card', {
          iconStyle: 'solid',
          style: {
            base: {
              iconColor: '#8c91a2',
              lineHeight: '1.2',
              fontWeight: 300,
              fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
              fontSize: '16px',

              '::placeholder': { color: '#8c91a2' },
            },
            invalid: {
              iconColor: '#e85746',
              color: '#e85746',
            }
          },
          // @ts-ignore
          classes: {
            focus: 'is-focused',
            empty: 'is-empty',
          },
        });
      }
      
    })

    this.card['mount']('#card-element')
    const inputs = document.querySelectorAll('input.field')

    Array.prototype.forEach.call(inputs, input => {
      input.addEventListener('focus', () => {
        input.classList.add('is-focused')
      })

      input.addEventListener('blur', () => {
        input.classList.remove('is-focused')
      })

      input.addEventListener('keyup', () => {
        if (input.value.length === 0) {
          input.classList.add('is-empty')
        } else {
          input.classList.remove('is-empty')
        }
      });
    });

    function setOutcome(result) {
      const successElement = document.querySelector('.success');
      const errorElement   = document.querySelector('.error');
      successElement.classList.remove('visible');
      errorElement.classList.remove('visible');

      if (result.token) {
        successElement.querySelector('.token').textContent = result.token.id;
        successElement.classList.add('visible');
      } else if (result.error) {
        errorElement.textContent = result.error.message;
        errorElement.classList.add('visible');
      }
    }

    this.card['on']('change', event => { setOutcome(event) } )

  }

  async stripeSaveNewCard() {
    let r = false
    let errorMsg : string
    try {
      this.loading = true
      const name  = this.stripe.get('name').value  
      const token = await this.stripeService.createToken(this.card, { name }).toPromise()
      if(!token.token) {
        console.log(token.error)
        if(token.error?.message)
          errorMsg = token.error.message
        throw new Error(errorMsg)
      } else {
        await this.paymentService.addNewCard(token.token.id).toPromise()
        r = true
      }
    } catch(e) {
      console.log(e)
    } finally {
      this.loading = false;
      this.dialogRef.close(r)
      if(!r)
        this.modalService.openAlertModal('Credit Card Failed', 
                                          errorMsg || 'Please try with a different Credit Card', 
                                          AlertType.ERROR)
    }
    return r
  }

  async goToPaymentPolicy() {
    const wl = await this.wl.getDynamicWhiteLabel() 
    const link = (_.get(wl?.content, 'payment_policy_link') || 
                  this.router.createUrlTree(['/text-reader/', 'payment-policy']).toString())                  
    window.open(link, '_blank')
  }

  async goToPrivacyPolicy() {
    const wl = await this.wl.getDynamicWhiteLabel()
    const link = (_.get(wl?.content, 'privacy_policy_link') ||
                  this.router.createUrlTree(['/text-reader/', 'privacy-policy']).toString())
    window.open(link, '_blank')
  }

}

