// dep
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

// app
import { environment as ENV } from '@environment'

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) {}

  getProducts(): Observable<any> {
    return this.http.get(`${ENV.billingApiUrl}/product/`);
  }

  editProducts(data): Observable<any> {
    return this.http.put(`${ENV.billingApiUrl}/product/${data.pid}`, data);
  }
}
