// dep
import { Component, OnInit , ChangeDetectorRef, OnDestroy, isDevMode, Inject} from '@angular/core'
import { FormGroup, FormBuilder, FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { DecimalPipe } from '@angular/common'
import { MatDialog } from '@angular/material/dialog'
import _ from 'lodash'
import { BehaviorSubject, combineLatest, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import jsPDF from "jspdf"
import "jspdf-autotable"

// app
import Group from '../../constants/firestore/group'
import User from '../../constants/firestore/user'
import {SubscriptionDetailed, CollectionTarget} from '../../constants/subscription'
import { Pageable } from '../../constants/pageable'
import { AuthService } from '../../services/auth.service'
import { GroupService } from '../../services/group.service'
import { InvoiceService } from '../../services/invoice.service'
import { PaymentsService } from '../../services/payments.service'
import { ModalService } from '../../services/modal.service'
import { SnackbarService } from '../../services/snackbar.service'
import { UserService } from '../../services/user.service'
import { SubscriptionService } from '../../services/subscription.service'
import { EmailerService } from '../../services/emailer.service'
import { WhiteLabelService } from '../../services/white-label.service'
import { ProductsEditModalComponent } from '../products/products-edit-modal/products-edit-modal.component'
import { ModalDeleteComponent } from '../../components/modal-delete/modal-delete.component'

// See https://docs.google.com/document/d/1NKVkPl6L3kJcwgRA8YN67pzObOWnN636OpuCld6qBD4
const CT = CollectionTarget
const COLLECTION_TARGET_DESC : { [ct in CollectionTarget] : string } = {
 [CT.MAPLABS_END_CUSTOMER] : "Stripe will manage the transaction: debit the total invoice amount from the customer credit card and credit the Map Labs Stripe account.",

 [CT.WL_OWNER]             : "Stripe will manage the transaction: debit the total invoice amount from the White Label Owner credit card and credit the Map Labs Account.",
 
 [CT.MAPLABS_OUT_OF_BAND]  : "Map Labs will manually collect the total invoice amount.", // End-Customer or WL Owner

 [CT.WL_END_CUSTOMER]      : ("Stripe will manage the transaction: debit the total invoice amount from the customer's credit card and credit the White Label Owner "+
                              "Stripe account minus a platform fee that will be credited to the Map Labs Stripe account."),

 [CT.WL_END_CUSTOMER_NO_FEE] : ("Stripe will manage the transaction: debit the total invoice amount from the customer's credit card and credit the White Label Owner "+
                                "Stripe account."),

 [CT.WL_OWNER_ONLY_FEE]    : ("Stripe will manage the transaction: debit the invoice platform fee from the White Label Owner credit card and credit the Map Labs "+
                              "Stripe account. The White Label Owner will manually collect the total invoice amount from the customer."),

 [CT.WL_OWNER_ONLY_FEE_NO_OOB] : ("Stripe will manage the transaction: debit the invoice platform fee from the White Label Owner credit card and credit the Map Labs "+
                                  "Stripe account."),

 [CT.WL_OUT_OF_BAND]       : "The White Label Owner will manually collect the total invoice amount from the customer.",
}


@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls:  ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit, OnDestroy {
  public isSuperAdmin = false;
  public formStatus: FormGroup;
  public formStripe: FormGroup;
  public group: Group;
  public user: User;
  public onDestroy$ = new Subject<void>()
  public paginate: Pageable = { page: 1, size: 10 };
  public dataSource = [];

  public loadingGroup = true
  public loadingUsers = true;
  public loadingInvoices = true;
  public loadingSubscription = true;
  public loadingSubscriptionTable = false;
  public loadingCards = true

  public dataSourceInvoices = [];
  public dataSourceSubs = [];
  public activeTab = 0;
  public statusOptions = [];
  public productSubsOptions = [];

  public readonly USER_COLS = [
    { header: 'Email', field: 'email' },
    { header: 'Name', field: 'displayName' },
    { header: 'Role', field:'role' },
    { header: 'Created On', field: 'createdOn' },
    { header: 'Signed On', field:'signedOn' }
  ] as const
  
  public readonly SUBSCRIPTIONS_COLS = [
    { header: 'Name', field: 'locationName', isCurrency : false },
    { header: 'Address', field: 'locationAddress', isCurrency : false },
    { header: 'Product', field:'pid', isCurrency : false },
    { header: 'Price', field: 'price', isCurrency: true },
    { header: 'Your Revenue', field: 'net', isCurrency: true },
    { header: 'Updated On', field: 'created', isCurrency : false },
  ] as const

  public readonly INVOICES_COLS = [
    { header: 'Name & Email', field: 'name', isCurrency : false},
    { header: 'Status', field: 'status', isCurrency: true },
    { header: 'Total', field: 'invoiceTotal', isCurrency: true },
    { header: 'Discount', field: 'invoiceDiscountTotal', isCurrency: true },
    { header: 'Map Labs Platform Fee', field: 'totalPlataformFee', isCurrency: true },
    { header: 'Created On', field: 'created', isCurrency : false },
    { header: 'Updated On', field: 'updated', isCurrency : false },
  ] as const
  
  public filterField : string

  public subscription : SubscriptionDetailed
  public groupId : string
  public uid : string

  private users = new BehaviorSubject<User[]>([])

  public cards : any[] = []
  public stripeCustomerDashboardURL : string | null = null
  public stripeCustomerDisplayMode : 'FIELD_EDITABLE' | 'LINK_ONLY' |'HIDE' = 'HIDE'
  public billingIsEditable = true

  public readonly trialEndMin  = new Date();
  public readonly formTrialEnd = new FormControl(new Date());

  constructor(
    // dep
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private decimalPipe: DecimalPipe,

    // app
    private wlService : WhiteLabelService,
    private userService: UserService,
    private route: ActivatedRoute,
    private groupService: GroupService,
    private _modalS: ModalService,
    private snack: SnackbarService,
    private authService: AuthService,
    public invoiceService: InvoiceService,
    private _subscriptionS: SubscriptionService,
    private emailerService: EmailerService,
    private paymentsService : PaymentsService
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    this.activeTab = history.state?.tabIndex;
    this.groupId   = history.state?.gid || sessionStorage.gid;

    sessionStorage.removeItem('gid');

    this.isSuperAdmin = !!this.authService.isSuperAdmin;

    this.formStatus = this.fb.group({
      gid: [''],
      company: [''],
    });

    this.formStripe = this.fb.group({
      customerId: [''],
      // subscriptionId: [''],
      // creditCard: [''],
      // activePlan: [''],
      billingOverride: [false],
    });

    if (!this.isSuperAdmin) 
      this.formStripe.controls['customerId'].disable()  

    this._fetchGroup()
    this.fetchGroupUsers(this.groupId)

    combineLatest([this.users.asObservable(), 
                   this.route.params])
                   .pipe(takeUntil(this.onDestroy$))
                   .subscribe(([users, params]) => 
      {
        const uid = params['id']
        this.uid  = uid
        this.user = _.find(users, { uid })
    })

    this._fetchSubscription()
    this.fetchInvoices()
    this.fetchCards()
  }

  private async fetchGroupUsers(gid : string) {
    this.userService.getUsersByGroup(gid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe( users => {
        const data = _.chunk(users, users.length);
        this.dataSource = data[0] || [];
        for(const el of this.dataSource) {
          if(el.createdAt) 
            el.createdOn = new Date(el.createdAt?.seconds * 1000)
          if(el.lastLogin) 
            el.signedOn  = new Date(el.lastLogin?.seconds * 1000)   
        }   
        this.users.next(users);
        this.loadingUsers = false;
      });
  }

  private async _fetchSubscription() : Promise<void> {
    try {
      this.loadingSubscriptionTable = true
      this.loadingSubscription      = true

      const sub = (await this._subscriptionS.fetchSubscription(this.groupId)).data
      
      this.subscription         = sub
      this.subscription.created = new Date(sub.startDate);
      this.subscription.id      = sub._id;

      this.dataSourceSubs   = this.subscription.products
      this.formStripe.get('billingOverride').setValue(this.subscription.billingOverride)
      
      this.formTrialEnd.setValue(new Date(sub.trialEnd)); // UTC->Local conversion

      this.productSubsOptions = [...new Set(sub.products.map(p => p.pid))].filter(pid => !!pid) 

      for(const p of sub.products) {
        p.created = new Date(p.startDate);
        p.net     = p.price - ((p.price * (sub.discount || 0)) / 100);
      }

    } finally {
      this.loadingSubscriptionTable = false
      this.loadingSubscription = false
      this.cdr.detectChanges()
    }
  }

  pageChange(event) {
    this.paginate = {
      page: event.first,
      size: event.rows
    }
  }

  private async fetchCards() {
    try {
      this.loadingCards = true
      this.cards = (await this.paymentsService.fetchCards(this.groupId)).data
    } finally {
      this.loadingCards = false
      this.cdr.detectChanges()
    }

  }

  private async _fetchGroup() : Promise<void> {
    try {
      this.loadingGroup = true
      const group = await this.groupService.getByDocId(this.groupId).toPromise()

      this.group = group

      this.formStatus.get('gid').setValue(this.groupId);
      this.formStatus.get('company').setValue(_.get(group, 'company', null));
      const form_customer_id = this.formStripe.get('customerId')

      form_customer_id.setValue(_.get(group, 'stripeCustomerId', null));

      // An WL Owner cannot edit his own stripeCustomerId, because
      // it belongs to the Maplabs account, nor it can change his billingOverride
      // setting.
      // TODO: Maybe don't show the stripeCustomerId at all 
      this.billingIsEditable = this.authService.isSuperAdmin || !group.isDomainOwner

      // Stripe Customer ID
      // - SuperAdmin can always see and edit it
      // - WL Owner 
      //    - if the group is the WL Owner himself, hide it
      //    - if the group is an End-Customer, show only a link to Stripe Dashboard
      this.stripeCustomerDisplayMode = this.authService.isSuperAdmin ? 'FIELD_EDITABLE' : 
                                    (group.isDomainOwner ? 'HIDE' : 'LINK_ONLY');
      

      const customerId = this.group.stripeCustomerId 

      if(!customerId || !this.billingIsEditable)
        this.stripeCustomerDashboardURL = null
      else {
        const stripe_account_id = this.authService.isSuperAdmin && !group.isDomainOwner ? await this.wlService.getStripeConnectAccountId(group.domain) : null
        this.stripeCustomerDashboardURL = ("https://dashboard.stripe.com" + 
                                           (stripe_account_id ? `/connect/accounts/${stripe_account_id}` : '') +
                                           `/customers/${customerId}`)
      }
      
    } finally {
      this.loadingGroup = false
      this.cdr.detectChanges()
    }
  }

  async onSaveGroupDetails(): Promise<void> {
    const {company} = this.formStatus.getRawValue();
    await this.updateGroup({company});
  }

  async updateGroup(groupChanges)  {
    await this.groupService.updateGroup(this.user.gid, groupChanges);
    // Fetch the changed data
    await this._fetchGroup()
  }


  //--------------------------------------------------------------------------
  // Billing
  //--------------------------------------------------------------------------

  async onSaveBilling() {
    const old_customerId      = this.group.stripeCustomerId
    const old_billingOverride = this.subscription.billingOverride

    const new_customerId      = this.formStripe.get('customerId').value
    const new_billingOverride = this.formStripe.get('billingOverride').value
 
    // TODO: Must disable "SAVE" buttons if details are unchanged
    if(new_customerId !== old_customerId) {
      if(!await this._modalS.openConfirmModal('Alert', 'Changing the Stripe Customer Id can cause billing errors or mischarges. ' + 
                                                       'It was needed in the past when changing the Billing Override flag, but is no more.' +
                                                       'Make sure you know what you are doing.'))
         return
      
      await this.updateGroup({stripeCustomerId: new_customerId})
      await this.fetchCards()
    } 

    if(new_billingOverride !== old_billingOverride) {

      if(new_billingOverride) {
        if(!await this._modalS.openConfirmModal('Alert','By clicking Confirm, you acknowledge that you understand “Billing Override” does not waive ' +
                                                        'the requirement to pay for a subscription to Map Labs and you are responsible for collecting ' +
                                                        'payment for these services directly with your Customer.')) { 
            // Restore previous value
            // => No, make the user change it by hand
            // this.formStripe.get('billingOverride').setValue(false)
            return
        }
      }

      if(this.requiresPaymentMethod() && !this.cards) {
        await this._modalS.openAlertModal('Customer with no Credit Card',
                                          "Your Customer must set up a Credit Card of his own to be billed, currently it has none."+
                                          "He will be alerted to add one on his next login");

      }
    
      await this._subscriptionS.changeBillingOverride(this.user.gid, new_billingOverride)
      // Fetch the changed data
      await this._fetchSubscription()
    }
  }

  getCardBrandImage(brand : string) : string {
    return PaymentsService.getCardBrandImage(brand)
  }

  private getCollectionForBillingOverrideShown() : SubscriptionDetailed['collectionByBillingOverride']['true'] {
    return this.subscription.collectionByBillingOverride[ 
      (!!this.formStripe.controls.billingOverride.value).toString()]
  }

  collectionTargetDesc() : string {
    return COLLECTION_TARGET_DESC[this.getCollectionForBillingOverrideShown().collectionTarget]
  }

  requiresPaymentMethod() : boolean {
    // const g = this.group
    return this.getCollectionForBillingOverrideShown().requiresPaymentMethod  // && (g.basicLocationsCount || g.ultimateLocationsCount)
  }

  billingOverrideField_mustBeShown() : boolean {
    // Billing flow case #5 ignores the Billing Override field, hide it.
    const c = this.subscription.collectionByBillingOverride
    return c['false'].collectionTarget !== c['true'].collectionTarget
  }


  //--------------------------------------------------------------------------
  // Users
  //--------------------------------------------------------------------------

  resetPassword(user) {
    this.emailerService.sendResetPasswordMail(user.email).subscribe(
      () => {
        this.snack.openSuccess('Reset password email send!', 4000);
      }, 
      e => {
        this.snack.openError('Reset password email failed, please try later!', 4000);
      }
    );
  }

  role(user: User): 'Admin' | 'Member' {
    return _.isEqual(user.role, 'admin') ? 'Admin' : 'Member';
  }

  async updateRole(user : User) {
    const role = _.isEqual(user.role, 'admin') && 'member' || 'admin';
    await this.userService.updateUser(user.gid, user.uid, {role});
    this.fetchGroupUsers(user.gid);
  }

  deleteUser(user : User) {
    const dialog = this._modalS.openGenericModal(ModalDeleteComponent, { name: user.displayName }, () => {}, 680);

    dialog.disableClose = true;
    const subscription = dialog.componentInstance.deleted.pipe(
      filter(r => r)
    ).subscribe(() => {
      this.userService.delete(user.gid, user.uid).then(() => {
        dialog.componentInstance.complete();
        setTimeout(() => {
          this.loadingUsers = true;
          this.fetchGroupUsers(user.gid);
          dialog.close();
          subscription.unsubscribe();
        }, 800);
      });
    });
  }


  async onImpersonate(user : User) {
    const token = (await this.userService.fetchImpersonateToken((user as any).id))['data'].token

    // let domain = this.wlService.baseDomain; // admin-frontend domain
    // if (domain == 'localhost') {
    //   if (isDevMode())
    //     domain += ':4200'
    //   else
    //     domain = "app.maplabs.com"
    // }

    let domain : string
    if (isDevMode()) {
      domain = 'localhost:4200'
    } else {
      // If the user is flagged with domainSurfing=false (default) then
      // the login domain should be the same the one his group belongs.
      // This is checked in main-frontend/main-api domain validation
      domain = (await this.groupService.getByDocId(user.gid).toPromise()).domain
    }

    // Open a new Tab with main-frontend in the user domain, impersonate-autologin as the user
    window.open(`https://${domain}/login/?impersonate_token=${token}`, '_blank');
  }

  //--------------------------------------------------------------------------
  // Invoices
  //--------------------------------------------------------------------------

  openAddProductModal(action) {
    this.loadingSubscriptionTable = true;
    const dialogRef = this.dialog.open(
      ProductsEditModalComponent,
      {
        width: '800px',
        data: {
          action: action
        }
      }
    );

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      this.loadingSubscriptionTable = false;
    });
  }

  async fetchInvoices() {
    try {
      this.loadingInvoices = true
      const [r_draft, r_invoices] = await Promise.all([this.invoiceService.fetchInvoiceNextMonthDraft(this.groupId, true),
                                                       this.invoiceService.getInvoices(this.groupId).toPromise()])

      const draft     = r_draft?.invoice   // Can be null if subscription.status == 'TRIAL'
      const invoices  = r_invoices['data']

      for(const inv of [...(draft ? [draft] : []), 
                         ...invoices]) {
        inv.name    = `${inv.user.displayName} - ${inv.user.email}`
        inv.date    = new Date(inv.date.$date);
        inv.updated = new Date(inv.updatedAt.$date)
        inv.created = new Date(inv.createdAt.$date)
        inv.isDraft = Object.is(inv, draft)
        inv.id      = inv.isDraft ? "Next (Draft)" : inv._id.$oid
        inv.status  = inv.isDraft ? "Next (Draft)" : inv.status.toLowerCase()
        inv.invoiceTotal         = this.decimalPipe.transform(inv.invoiceTotal, '1.2-2')
        inv.invoiceDiscountTotal = this.decimalPipe.transform(inv.invoiceDiscountTotal, '1.2-2')
        inv.invoiceFeeTotal      = this.decimalPipe.transform(inv.invoiceFeeTotal, '1.2-2')
      }

      this.dataSourceInvoices = [...(draft ? [draft] : []), 
                                 ...invoices.sort((a, b) => b.created - a.created)]
      this.getStatus()
    } catch(e) {
      console.error("Error fetching invoices", e)
    } finally {
      this.loadingInvoices = false;
      this.cdr.detectChanges();
    }
  }

  getStatus() {
    const data = {};
    const domains = this.dataSourceInvoices.map(el => el.status).filter((indice) => {
      return data.hasOwnProperty(indice) ? false : (data[indice] = true);
    });
    domains.forEach(el => {
      if (el != null && el != undefined) {
        this.statusOptions.push(el.toUpperCase())
      }
    });
  }

  async selectInvoices(invoice) : Promise<void> {
    await this.router.navigate(
      [`admin/invoice-details`],
      {
        state: {
          'invoice': invoice,
          'previousPage': 'group',
          'gid' : this.groupId
        }
      }
    )
  }

  exportPdf(title, cols, table) {
    const data = table.filteredValue || (
                 title === 'invoices' ? this.dataSourceInvoices /*this.dataSourceInvoices.filter(inv => !inv.isDraft)*/ :
                 (title === 'subscription' ? this.dataSourceSubs : this.dataSource))
    
    const doc = new jsPDF('p','pt', [window.innerWidth, window.innerHeight]);
    const exportColumns = cols.map(col => ({title: col.header, dataKey: col.field}));
    doc['autoTable'](exportColumns, data);
    doc.save(`${title}.pdf`);
  }

  clearTable(table) {
    table.clear();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async handleChangeSubscriptionStatus(newValue : 'TRIAL' | 'ACTIVE' | 'BLOCKED') {    
    await this._changeStatus({ status : newValue });
  }

  async handleTrialEndChanged(newLocalDate : Date) {
    // Local->UTC conversion
    const trialEnd = newLocalDate.toISOString().split('T')[0];
    await this._changeStatus({ status : 'TRIAL', trialEnd });
  }

  private async _changeStatus(newStatus) {
    try {
      this.loadingSubscriptionTable = true;
      this.loadingSubscription      = true;
      const r = await this._subscriptionS.changeStatus(this.groupId, newStatus);
      await this._fetchSubscription();
      if(r === 'ERROR_HAS_UNPAID_INVOICES' && newStatus.status === 'TRIAL') {
        await this._modalS.openErrorModal('Cannot change status',
                                          "You cannot change the status to Trial as this Subscription has unpaid Invoices. "+
                                          "Void the Invoices before changing the status."); 
      }
    } finally {
      this.loadingSubscription      = false;
      this.loadingSubscriptionTable = false;
    }
  }

}
