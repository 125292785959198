import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import * as _ from 'lodash';

import jsPDF from "jspdf";
import "jspdf-autotable";

import User from 'src/app/constants/firestore/user';
import { Sort } from 'src/app/constants/sort';

import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { WhiteLabelService } from '../../services/white-label.service';
import { DomainService } from '../../services/domain.service';
import { GroupService } from '../../services/group.service';
import { Pageable } from 'src/app/constants/pageable';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';
import { ModalDeleteComponent } from 'src/app/components/modal-delete/modal-delete.component';
import { filter } from 'rxjs/operators';
import { EmailerService } from 'src/app/services/emailer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public user: User;
  public resultUsers: User[];
  public domain: string;
  public domains$;
  public domains;
  public sort: Sort;
  public previousPageable: { size: any; page: any };
  public filterField: string;
  public loading: boolean = true;
  // public isAllSelected: boolean;
  public filterDomain: string;
  public paginate: Pageable = { page: 1, size: 10 };
  public dataSource = [];
  public domainsOptions = [];
  public cols = [
    { header: 'Email', field: 'email' },
    { header: 'Name', field: 'displayName' },
    { header: 'Domain', field:'registrationDomain' },
    { header: 'Created On', field: 'createdOn' }
  ];
  public isSuperAdmin;

  constructor(
    private snack: SnackbarService,
    private emailerService: EmailerService,
    public userService: UserService,
    public auth: AuthService,
    public wl: WhiteLabelService,
    public domainService: DomainService,
    private cdr: ChangeDetectorRef,
    public groupService: GroupService,
    private router: Router,
    private modalService: ModalService,
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    this.user = this.auth.session;
    this.isSuperAdmin = this.user?.isSuperAdmin;

    if (!this.isSuperAdmin) {
      this.cols.splice(2, 1);
    }
    this.initSort();
    this.userService.setUsers([]);
    this.getUsers();
  }

  getUsers() {
    this.userService.getAllUsers().subscribe( result => {
      this.domain = this.wl.baseDomain;
      this.domains$ = this.domainService.getDomains();
      if (!result || result.length === 0) {
        return;
      }
      const results = [];
      result.forEach(item => {
        if(!results.find(el => el['uid'] === item.uid)) {
          results.push(item);
        }
      });
      this.resultUsers = _.orderBy(results, ['displayName'], ['asc']);
      this.userService.setUsers(results);
      for (const index of results) {
        index.id = JSON.stringify(index);
      }
      this.domain = this.auth.session.isSuperAdmin ? 'all' : this.wl.baseDomain;
      this.domains$.subscribe(
        res => {
          this.domains =  this.domainService.getOptionsDomains(res.domains);
          this.getByDomain({ value: this.domain });
        }
      );
    });
    this.cdr.detectChanges();
  }

  getByDomain({ value }): void {
    this.initPaginator();
    this.initSort();

    if ('all' !== value) {
      const domain = (value === 'localhost' ? 'localhost:4200' : value.replace(/\_/g, '.'))
      this.filterDomain = domain;
      this.getData();
    } else {
      this.setData(this.resultUsers);
    }
  }

  getData() {
    this.paginate = { page: 1, size: 10000 };
    let last = null;
    let next = null;

    this.loading = true;
    this.userService.getUsers(this.paginate, this.filterDomain, last, next, this.sort['sortBy'], this.sort['direction']).subscribe(
      (result) => {
        this.initPaginator();
        this.buildData(result.items);
        this.getDomains();
        this.getCreatedOn();
        this.loading = false;
      });
  }

  setData(results) {
    this.buildData(results);
    this.getDomains();
    this.getCreatedOn();
    this.loading = false;
  }

  buildData(data) {
    this.dataSource = [];
    data.forEach(el => {
      if (this.domains.findIndex(i => i == el.registrationDomain) != -1) {
        this.dataSource.push(el);
      }
    });
  }

  getDomains() {
    const domains = this.dataSource.map(el => el.registrationDomain);
    const domainsSet = [...new Set(domains)];
    this.domainsOptions = domainsSet.filter(el => el != null && el != undefined);
  }

  getCreatedOn() {
    this.dataSource.forEach(el => {
      if(el.createdAt) {
        el.createdOn = new Date(el.createdAt?.seconds * 1000)
      }
    });
    this.dataSource = this.dataSource.sort((a, b) => {return b.createdOn - a.createdOn});
  }

  clearTable(table) {
    table.clear();
    this.filterField = null;
    this.initSort();
  }

  exportPdf(table) {
    const data = table.filteredValue || this.dataSource;
    const exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    const doc = new jsPDF('p','pt', [window.innerWidth, window.innerHeight]);
    doc['autoTable'](
      exportColumns,
      data,
      {
        columnStyles: {
          0: {columnWidth: 120},
          1: {columnWidth: 120}
      }
    });
    doc.save("users.pdf");
  }

  pageChange(event) {
    this.paginate = {
      page: event.first,
      size: event.rows
    }
  }

  initPaginator(): void {
    this.paginate = { page: 1, size: 10 };
  }

  initSort(): void {
    this.sort = {
      sortBy: 'displayName',
      direction: 'asc',
    };
  }

  resetPassword(user: any): void {
    this.emailerService.sendResetPasswordMail(user.email).subscribe(
      () => {
        this.snack.openSuccess('User Verified and Reset password email send!', 4000);
      }, e => {
        this.snack.openError('User Verified and Reset password email failed, please try later!', 4000);
      }
    );
  }

  verifyUserResetPassword(user: any): void{
    this.groupService.markUserEmailIsVerified(user)
    this.resetPassword(user);
  }

  deleteUser(user: any): void {
    const data = { name: user.displayName };
    const dialog = this.modalService.openGenericModal(ModalDeleteComponent, data, () => {}, 680);

    dialog.disableClose = true;
    const subscription = dialog.componentInstance.deleted.pipe(
      filter(r => r)
    ).subscribe(() => {
      this.userService.delete(user.gid, user.uid).then(() => {
        dialog.componentInstance.complete();
        setTimeout(() => {
          this.ngOnInit();
          dialog.close();
          subscription.unsubscribe();
        }, 800);
      });
    });
  }

  selectUser(user) {
    this.router.navigate(
      [`admin/group/${user.uid}`],
      {state: {'tabIndex': 0, 'gid': user.gid}}
    ).then();
  }
}
