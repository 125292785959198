<div class="subscriptions">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h2 class="m--0"><strong>Subscriptions</strong></h2>
  </div>

  <app-skeletons [visibleSection]="'subscriptions'" *ngIf="loading"></app-skeletons>

  <div class="subscriptions-table" *ngIf="!loading || !firstLoading" [ngClass]="loading ? 'table-hide' : ''">
    <p-table #dt 
      [value]="dataSource" 
      [columns]="cols" 
      sortField="created" 
      [sortOrder]="-1"
      styleClass="p-datatable" 
      [rowHover]="true"
      [responsive]="true"
      [paginator]="true" 
      [rows]="paginate?.size"
      [totalRecords]="paginate?.totalPages*paginate?.size"
      [showCurrentPageReport]="true" 
      [rowsPerPageOptions]="[10,25,50,100]"
      [lazy]="true"
      [first]="skipRecords"
      currentPageReportTemplate="{{dt.filteredValue?.length || dataSource?.length}} entries"
      (onPage)="pageChange($event)"
      (onSort)="sortChange($event)"
      [filterDelay]="0"
      [globalFilterFields]="['name', 'status', 'basic', 'essential', 'ultimate', 'created', 'nextDue', 'domain', 'gid', 'id', 'lastInvoicePaidDate']"
    >
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="input-field--search filter-table">
            <section class="table-actions">
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort"
                matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="clearTable(dt, locDt)">
                <em class="pi pi-filter-slash"></em>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="dt.exportCSV()">
                <em class="pi pi-file"></em>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf(dt)">
                <em class="pi pi-file-pdf"></em>
              </button>
              <p-dropdown *ngIf="domainsOptions.length > 1" class="domains-filter" [showClear]="true" [(ngModel)]="domainValue" [options]="domainsOptions" placeholder="Domains" (onChange)="domainSearch($event?.value)" (onClear)="domainSearch(null)">
              </p-dropdown>
            </section>
            <section class="table-filter">
              <div class="table__icon table__icon--lg">
                <em class="fas fa-search"></em>
              </div>
            </section>
            <input pInputText type="text" [ngModel]="inputSearch"
              (input)="inputFilterSeach($event?.target?.value)" placeholder="Type to search"/>
          </div>
        </div>
        <section class="panel-locations">
          <p-panel header="View Total Locations" [toggleable]="true" [collapsed]="true">
            <p-table #locDt [value]="domainDataSource" [globalFilterFields]="['domain']" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <ng-container *ngFor="let col of DOMAIN_COLS">
                    <th scope="col">{{col.header}}</th>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-domainDataSource>
                <tr>
                  <td><span [outerHTML]="domainDataSource?.domain"></span></td>
                  <td><span [outerHTML]="domainDataSource?.data?.essential | isEmpty"></span></td>
                  <td><span [outerHTML]="domainDataSource?.data?.basic | isEmpty"></span></td>
                  <td><span [outerHTML]="domainDataSource?.data?.ultimate | isEmpty"></span></td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </section>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of cols">
            <th [pSortableColumn]="checkSortableColumn(col?.field) ? col?.field : null" [class.is-currency]="col.isCurrency">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{col.header | titlecase}}
                <p-sortIcon *ngIf="checkSortableColumn(col?.field)" field="{{col.field}}"></p-sortIcon>
                <p-columnFilter *ngIf="col.field === 'status'" field="{{col.field}}" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" matchMode="in"  [showClearButton]="false" [showApplyButton]="false">
                  <ng-template pTemplate="item" let-value let-filter="filterCallback" >
                      <p-dropdown #selectedStatus [ngModel]="statusFilter" [options]="statusOptions" placeholder="Status">
                      </p-dropdown>
                      <div class="d-flex justify-content-between align-items-center buttons-filter">
                      <p-button label="Clear" styleClass="p-button-outlined" (onClick)="filterTable(undefined)"></p-button>
                      <p-button label="Apply" (onClick)="filterTable(selectedStatus.value)"></p-button>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dataSource>
        <tr class="p-selectable-row" (click)="selectSubscription(dataSource)">
          <td class="td-width-xm align-left-table"><span [outerHTML]="dataSource?.name | titlecase | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.status | titlecase | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.essential || 0"></span></td>
          <td><span [outerHTML]="dataSource?.basic || 0"></span></td>
          <td><span [outerHTML]="dataSource?.ultimate || 0"></span></td>
          <td><span [outerHTML]="dataSource?.created | date: 'MM/dd/yy' | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.nextDue | date: 'MM/dd/yy' | isEmpty"></span></td>
          <td matTooltip={{getTotalTooltip(dataSource)}}><span [outerHTML]="dataSource?.lastInvoicePaidDate| date: 'MM/dd/yy' | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.domain"></span></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No subscription found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
