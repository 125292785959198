import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  template: `
	  <div class="dialog__header txt--center dialog__header--warn"
		   [ngClass]="{'dialog__header--warn': data.alertType == 1, 'dialog__header--info': data.alertType == 0, 'dialog__header--error': data.alertType == 2}">
		  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{ data.title }}</strong></h2>
	  </div>

	  <div class="dialog__content" mat-dialog-content>
		  <div class="dialog-row">
			  <div class="txt--lg txt--center m--0" [innerHTML]="data.content"></div>
		  </div>
	  </div>

	  <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
        <button *ngIf="data.alertType != 0" mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
      </span>
        <button tabIndex="-1" mat-flat-button color="primary" [mat-dialog-close]="data.title" class="btn btn--action">{{ data.closeButtonLabel }}</button>
	  </div>
  `,
})
export class AlertComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ModalAlertData
  ) {
  }

  getAlertIcon() {
    switch (this.data.alertType) {
      case AlertType.INFO:
        return 'info';
      case AlertType.WARNING:
        return 'warning';
      case AlertType.ERROR:
        return 'error';
    }
  }
}

export class ModalAlertData {
  title: string;
  content: string;
  alertType: AlertType;
  closeButtonLabel: string;

  constructor(data?) {
    if (data) {
      this.title = data.title;
      this.content = data.content;
      this.alertType = data.alertType;
      this.closeButtonLabel = data.closeButtonLabel;
    }
  }
}

export enum AlertType {
  INFO, WARNING, ERROR
}
