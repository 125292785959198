import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '@environment';
// import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiResponse } from '../constants/api-response';
import { Pageable } from '../constants/pageable';

export interface LogGrade  {
  accountId: string;
  locationId: string;
  locationName: string;
  email: string;
  displayName: string;
}

export interface LogUser  {
  email: string;
  uid: string;
  displayName: string;
  role: string;
}


export interface Log {
  domain: string;
  gid: string;
  create_at: Date;
  type_log: "User" | "Location" | "Subscription" | "Grade";
  message: string;
  meta_data: LogGrade | LogUser;
}

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  constructor(
    private http: HttpClient,
    ) { }

   /**
   * this method get log
   * @param log is the object to save new log
   */
  getLogs(pageable: Pageable, filter?: {domain: string, type_log: string}, gid?: string, uid?: string) {
    const page = pageable.page.toString();
    const size = pageable.size.toString();

    return this.http.post<ApiResponse>(`${environment.apiUrl}/v2/logs?page=${page}&size=${size}`, {...filter})
      .pipe(map(value => value.data));
  }

}
