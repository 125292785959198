import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from "@environment";

import { HEADERS_NO_AUTH }  from "../constants/auth"

@Injectable({
  providedIn: 'root'
})
export class EmailerService {

  constructor(private http: HttpClient) {
  }

  // TODO: Not used, remove?
  // send(email: string, subject: string, message: string, headers) {
  //   const body = {
  //     'to': email,
  //     'subject': subject,
  //     'message': message
  //   };
  // 
  // return this.http.post(`${environment.apiUrl}/v2/emailer/send`, body, { ...headers })
  // }

  sendResetPasswordMail(email : string) {
    return this.http.post(`${environment.apiUrl}/v2/auth/reset`, { email }, HEADERS_NO_AUTH);
  }

  // TODO: not used, remove?
  // subscriberMailerLite(email: string, name: string, company: string, domain: string, headers) {
  //   const body = { email, name, company, domain };
  //   return this.http.post(`${environment.apiUrl}/v2/emailer/ml/subscriber`, body, {...headers})
  // }
  // 
  // modifyMailerLite(status: string, upgraded: number, locations: number, email: string, headers, updateAll?: boolean) {
  //   const data = { 'status': status , 'upgraded': upgraded, 'locations': locations, 'email': email, 'update_all': updateAll };
  //     return this.http.post(`${environment.apiUrl}/v2/emailer/ml/modify`, data, {...headers})
  //   }
  
}
