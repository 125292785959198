// TODO: Merge with http-auth.interceptor.ts, create a single interceptor managing all the
// app interceptions

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { WhiteLabelService } from '../services/white-label.service';
import { Injectable } from "@angular/core";

/**
 * Ensure all outgoing HTTP requests contain the "X-Domain" header
 */
@Injectable()
export class HttpDomainInterceptor implements HttpInterceptor {

  constructor(private wl: WhiteLabelService) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('X-domain')) 
      req = req.clone({ headers: req.headers.set('X-domain', this.wl.slugDomain) })
    
    return next.handle(req)
  }
}
