<ng-container *ngIf="visibleSection === 'settings'">
  <ng-container *ngIf="activeTab == 0">
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>General Settings</strong></h2>
    </div>

    <div class="col-12 box--padding-xxs">
      <div class="col-4" style="float: left;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '20px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="col-4" style="float: right;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="col-4" style="float: right;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 1">
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Emails</strong></h2>
    </div>

    <div class="col-12 box--padding-xxs">
      <div class="col-6" style="float: left;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="col-6" style="float: right;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="col-12 box--padding-xxs">
        <ngx-skeleton-loader style="margin-top: '20px'; float: right;" count="1" [theme]="{ width: '250px', height: '46px' }"></ngx-skeleton-loader>
      </div>
    </div>

    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>MailerLite Setup</strong></h2>
    </div>

    <div class="col-12 box--padding-xxs">
      <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '100%', height: '20px' }"></ngx-skeleton-loader>
    </div>

    <div class="col-12">
      <ngx-skeleton-loader style="float: right;" count="1" [theme]="{ width: '250px', height: '46px' }"></ngx-skeleton-loader>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 2">
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Branding</strong></h2>
    </div>

    <ng-container *ngFor="let n of [0,1]">
      <div class="col-12 box--padding-xxs">
        <div class="col-6" style="float: left;">
          <ngx-skeleton-loader style="display: block;" count="1" [theme]="{ width: '30%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="display: block;" count="1" [theme]="{ width: '50%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="margin-bottom: 15px; margin-bottom: -8px;" count="1" [theme]="{ width: '100%', height: '206px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="display: block; margin-bottom: -8px; margin-top: -6px;" count="1" [theme]="{ width: '30%', height: '10px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="display: block; margin-bottom: -8px;" count="1" [theme]="{ width: '30%', height: '10px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="display: block; margin-bottom: -8px;" count="1" [theme]="{ width: '30%', height: '10px' }"></ngx-skeleton-loader>
        </div>
        <div class="col-6" style="float: right;">
          <ngx-skeleton-loader style="display: block;" count="1" [theme]="{ width: '30%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="display: block;" count="1" [theme]="{ width: '60%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="margin-bottom: 15px; margin-bottom: -8px;" count="1" [theme]="{ width: '100%', height: '206px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="display: block; margin-bottom: -8px; margin-top: -6px;" count="1" [theme]="{ width: '30%', height: '10px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="display: block; margin-bottom: -8px;" count="1" [theme]="{ width: '30%', height: '10px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="display: block; margin-bottom: -8px;" count="1" [theme]="{ width: '30%', height: '10px' }"></ngx-skeleton-loader>
        </div>
      </div>

      <div class="col-12 box--padding-xxs">
        <div class="col-6" style="float: left; margin-top: 20px;">
          <ngx-skeleton-loader style="display: block;" count="1" [theme]="{ width: '30%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="margin-right: 15px;" count="1" [theme]="{ width: '50%', height: '40px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="margin-right: 15px;" count="1" [theme]="{ width: '20%', height: '40px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '20%', height: '40px' }"></ngx-skeleton-loader>
        </div>
        <div class="col-6" style="float: left; margin-top: 20px;">
          <ngx-skeleton-loader style="display: block;" count="1" [theme]="{ width: '30%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="margin-right: 15px;" count="1" [theme]="{ width: '50%', height: '40px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="margin-right: 15px;" count="1" [theme]="{ width: '20%', height: '40px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '20%', height: '40px' }"></ngx-skeleton-loader>
        </div>
      </div>
    </ng-container>

    <div class="col-12 box--padding-xxs">
      <ng-container *ngFor="let n of [0,1,2,3]">
        <div class="col-6" style="float: left; margin-top: 20px;">
          <ngx-skeleton-loader style="display: block;" count="1" [theme]="{ width: '30%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="margin-right: 15px;" count="1" [theme]="{ width: '100%', height: '40px' }"></ngx-skeleton-loader>
        </div>
      </ng-container>

      <div class="col-12" style="float: left; margin-top: 20px;">
        <ngx-skeleton-loader style="display: block; margin-right: 15px" count="1" [theme]="{ width: '10%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-right: 15px;" count="1" [theme]="{ width: '100%', height: '50px' }"></ngx-skeleton-loader>
      </div>

      <ng-container *ngFor="let n of [0,1,2,3,4]">
        <div class="col-6" style="float: left; margin-top: 20px;">
          <ngx-skeleton-loader style="display: block;" count="1" [theme]="{ width: '30%', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="margin-right: 15px;" count="1" [theme]="{ width: '100%', height: '40px' }"></ngx-skeleton-loader>
        </div>
      </ng-container>
    </div>

    <div class="col-12" style="clear: both;">
      <ngx-skeleton-loader style="float: right;" count="1" [theme]="{ width: '250px', height: '46px' }"></ngx-skeleton-loader>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 3">
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Stripe</strong></h2>
    </div>

    <div class="col-12 box--padding-xxs">
      <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '20px' }"></ngx-skeleton-loader>
    </div>

  </ng-container>

  <ng-container *ngIf="activeTab == 4">
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Payment Method</strong></h2>
    </div>

    <div class="box--padding-xxs">
      <ngx-skeleton-loader count="1" style="margin-right: 15px;" [theme]="{ width: '140px', height: '30px' }"></ngx-skeleton-loader>
    </div>

    <ng-container *ngFor="let n of [0,1,2]">
      <div class="box--padding-xxs border-bottom">
        <div class="row align-items-center">
          <div class="col-4">
            <div class="d-flex align-items-center">
              <ngx-skeleton-loader count="1" style="margin-right: 15px;" [theme]="{ width: '60px', height: '38px' }"></ngx-skeleton-loader>
              <div>
                <ngx-skeleton-loader count="1" style="margin-right: 15px; display: block;" [theme]="{ width: '70px', height: '15px' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" style="margin-right: 15px; display: block;" [theme]="{ width: '70px', height: '15px' }"></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="col-3">
            <ngx-skeleton-loader count="1" style="margin-right: 15px; display: block;" [theme]="{ width: '80px', height: '15px' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" style="margin-right: 15px; display: block;" [theme]="{ width: '80px', height: '15px' }"></ngx-skeleton-loader>
          </div>
          <div class="col-3">
            <ngx-skeleton-loader count="1" style="margin-right: 15px;" [theme]="{ width: '270px', height: '29px' }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="visibleSection === 'domains'">
  <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
    <ngx-skeleton-loader count="1" [theme]="{ width: '10%', height: '40px' }"></ngx-skeleton-loader>
  </section>

  <table class="table table--sm table--last-row-border bg--white" aria-describedby="domains">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell"><strong>Domain</strong></th>
      <th class="mat-header-cell"><strong>Essential</strong></th>
      <th class="mat-header-cell"><strong>Basic</strong></th>
      <th class="mat-header-cell"><strong>Ultimate</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '400px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <ng-container *ngFor="let i of [0,1,2]">
        <td class="mat-cell">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="visibleSection === 'users'">
  <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
    <ngx-skeleton-loader count="1" [theme]="{ width: '10%', height: '40px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '38%', height: '40px', float: 'right' }"></ngx-skeleton-loader>
  </section>

  <table class="table table--sm table--last-row-border bg--white" aria-describedby="users">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell"><strong>Email</strong></th>
      <th class="mat-header-cell"><strong>Name</strong></th>
      <th *ngIf="isSuperAdmin" class="mat-header-cell"><strong>Domain</strong></th>
      <th class="mat-header-cell"><strong>Created On</strong></th>
      <th class="mat-header-cell"><strong>Actions</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">

      <td class="mat-cell txt--left">
        <ngx-skeleton-loader count="1" [theme]="{ width: '250px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '250px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell" *ngIf="isSuperAdmin">
        <ngx-skeleton-loader count="1" [theme]="{ width: '250px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell mat-column-actions txt--right">
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="visibleSection === 'products'">
  <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
    <ngx-skeleton-loader count="1" [theme]="{ width: '15%', height: '40px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '38%', height: '40px', float: 'right' }"></ngx-skeleton-loader>
  </section>

  <table class="table table--sm table--last-row-border bg--white" aria-describedby="products">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell"><strong>Name</strong></th>
      <th class="mat-header-cell"><strong>Description</strong></th>
      <th class="mat-header-cell"><strong>Domain</strong></th>
      <th class="mat-header-cell"><strong>Price</strong></th>
      <th class="mat-header-cell"><strong>Map Labs Platform Fee</strong></th>
      <th class="mat-header-cell"><strong>Updated On</strong></th>
      <th class="mat-header-cell"><strong>Actions</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '250px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell txt--right">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell txt--right">
        <ngx-skeleton-loader count="1" [theme]="{ width: '182px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell mat-column-actions txt--right">
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="visibleSection === 'subscriptions'">
  <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
    <ngx-skeleton-loader count="1" [theme]="{ width: '10%', height: '40px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '38%', height: '40px', float: 'right' }"></ngx-skeleton-loader>
  </section>

  <table class="table table--sm table--last-row-border bg--white" aria-describedby="subs">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell"><strong>Name & Email</strong></th>
      <th class="mat-header-cell"><strong>Status</strong></th>
      <th class="mat-header-cell"><strong>Essential</strong></th>
      <th class="mat-header-cell"><strong>Basic</strong></th>
      <th class="mat-header-cell"><strong>Ultimate</strong></th>
      <th class="mat-header-cell"><strong>Created On</strong></th>
      <th class="mat-header-cell"><strong>Next Billing Date</strong></th>
      <th class="mat-header-cell"><strong>Last Invoice</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <ng-container *ngFor="let i of [0,1,2,3,4,5,6]">
        <td class="mat-cell">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="visibleSection === 'invoices' || visibleSection === 'groups-invoice-details'">
  <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
    <ngx-skeleton-loader count="1" [theme]="{ width: '10%', height: '40px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '38%', height: '40px', float: 'right' }"></ngx-skeleton-loader>
  </section>

  <table class="table table--sm table--last-row-border bg--white" aria-describedby="invoice">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell"><strong>Name & Email</strong></th>
      <th class="mat-header-cell" *ngIf="isSuperAdmin && visibleSection === 'invoices'"><strong>Domain</strong></th>
      <th class="mat-header-cell"><strong>Status</strong></th>
      <th class="mat-header-cell"><strong>Total</strong></th>
      <th class="mat-header-cell"><strong>Discount</strong></th>
      <th class="mat-header-cell"><strong>Map Labs Platform Fee</strong></th>
      <th class="mat-header-cell"><strong>Created On</strong></th>
      <th class="mat-header-cell"><strong>Updated On</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
      <td class="mat-cell txt--left">
        <ngx-skeleton-loader count="1" [theme]="{ width: '300px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell" *ngIf="isSuperAdmin && visibleSection === 'invoices'">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <ng-container *ngFor="let n of [0,1,2,3,4,5,6]">
        <td class="mat-cell">
          <ngx-skeleton-loader count="1" [theme]="{ width: '80px', height: '20px' }"></ngx-skeleton-loader>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="visibleSection === 'invoices-details'">
  <section>
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Summary</strong></h2>
    </div>

    <div class="col-6 box--padding-xxs" style="float: left;">
      <ng-container *ngFor="let n of [0,1,2]">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      </ng-container>
    </div>

    <div class="col-6 box--padding-xxs" style="float: right;">
      <ng-container *ngFor="let n of [0,1,2]">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      </ng-container>
    </div>
  </section>

  <section class="clearfix">
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Invoice Details</strong></h2>
    </div>

    <div class="box--padding-xxs">
      <ng-container *ngFor="let n of [0,1,2]">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      </ng-container>
    </div>
  </section>

  <section>
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Items</strong></h2>
    </div>

    <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
      <ngx-skeleton-loader count="1" [theme]="{ width: '10%', height: '40px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ width: '38%', height: '40px', float: 'right' }"></ngx-skeleton-loader>
    </section>

    <table class="table table--sm table--last-row-border bg--white" aria-describedby="invoice">
      <thead>
      <tr class="mat-row no-hover">
        <th class="mat-header-cell"><strong>Description</strong></th>
        <th class="mat-header-cell"><strong>Price</strong></th>
        <th class="mat-header-cell"><strong>Map Labs Platform Fee</strong></th>
        <th class="mat-header-cell"><strong>Discount</strong></th>
        <th class="mat-header-cell"><strong>Your Revenue</strong></th>
      </tr>
      </thead>
      <tbody>
      <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
        <ng-container *ngFor="let n of [0,1,2,3,4]">
          <td class="mat-cell">
            <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </section>
</ng-container>

<ng-container *ngIf="visibleSection === 'view-logs'">
  <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
    <ngx-skeleton-loader count="1" [theme]="{ width: '5%', height: '40px' }"></ngx-skeleton-loader>
  </section>

  <table class="table table--sm table--last-row-border bg--white" aria-describedby="logs">
    <thead>
    <tr class="mat-row no-hover">
      <th *ngIf="isSuperAdmin" class="mat-header-cell"><strong>Domain</strong></th>
      <th class="mat-header-cell"><strong>Gid</strong></th>
      <th class="mat-header-cell"><strong>Type Log</strong></th>
      <th class="mat-header-cell"><strong>Message</strong></th>
      <th class="mat-header-cell"><strong>Created On</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">

      <td class="mat-cell" *ngIf="isSuperAdmin">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '300px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="visibleSection === 'grade-leads'">
  <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
    <ngx-skeleton-loader count="1" [theme]="{ width: '10%', height: '40px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '38%', height: '40px', float: 'right' }"></ngx-skeleton-loader>
  </section>

  <table class="table table--sm table--last-row-border bg--white" aria-describedby="grade">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell"><strong>Account Name</strong></th>
      <th class="mat-header-cell"><strong>Location Name</strong></th>
      <th class="mat-header-cell"><strong>Email</strong></th>
      <th *ngIf="isSuperAdmin" class="mat-header-cell"><strong>Domain</strong></th>
      <th class="mat-header-cell"><strong>Created On</strong></th>
      <th class="mat-header-cell"><strong>Actions</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">

      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell" *ngIf="isSuperAdmin">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell mat-column-actions txt--right">
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="visibleSection === 'groups-details'">
  <section>
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Group Status</strong></h2>
    </div>

    <div class="col-6 box--padding-xxs" style="float: left;">
      <div class="col-6" style="float: left;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="col-6" style="float: right;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      </div>
    </div>

    <div class="col-6 box--padding-xxs" style="float: right;">
      <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="visibleSection === 'groups-billing'">
  <section class="clearfix">
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Billing</strong></h2>
    </div>

    <div class="col-12 box--padding-xxs" style="float: left;">
      <div class="col-4" style="float: left;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '40px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <div class="col-4" style="float: right;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '40px' }"></ngx-skeleton-loader>
      </div>
      <div class="col-4" style="float: right;">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '40px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="visibleSection === 'groups-users-details'">
  <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
    <ngx-skeleton-loader count="1" [theme]="{ width: '10%', height: '40px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '38%', height: '40px', float: 'right' }"></ngx-skeleton-loader>
  </section>

  <table class="table table--sm table--last-row-border bg--white" aria-describedby="users">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell"><strong>Email</strong></th>
      <th class="mat-header-cell"><strong>Name</strong></th>
      <th class="mat-header-cell"><strong>Role</strong></th>
      <th class="mat-header-cell"><strong>Created On</strong></th>
      <th class="mat-header-cell"><strong>Signed On</strong></th>
      <th class="mat-header-cell"><strong>Actions</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell">
        <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px' }"></ngx-skeleton-loader>
      </td>
      <td class="mat-cell mat-column-actions txt--right">
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="visibleSection === 'groups-subs-details'">
  <section>
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Subscription Details</strong></h2>
    </div>

    <div class="col-6 box--padding-xxs" style="float: left;">
      <ng-container *ngFor="let n of [0,1,2]">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      </ng-container>
    </div>

    <div class="col-6 box--padding-xxs" style="float: right;">
      <ng-container *ngFor="let n of [0,1]">
        <ngx-skeleton-loader style="margin-bottom: '15px'" count="1" [theme]="{ width: '95%', height: '20px' }"></ngx-skeleton-loader>
      </ng-container>
    </div>
  </section>

  <section class="clearfix">
    <div class="box__header">
      <h2 class="txt--capitalize txt--black m--0"><strong>Pricing</strong></h2>
    </div>

    <section class="table--last-row-border bg--white" style="padding: 10px 20px;">
      <ngx-skeleton-loader count="1" [theme]="{ width: '10%', height: '40px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ width: '38%', height: '40px', float: 'right' }"></ngx-skeleton-loader>
    </section>

    <table class="table table--sm table--last-row-border bg--white" aria-describedby="subs">
      <thead>
      <tr class="mat-row no-hover">
        <th class="mat-header-cell"><strong>Name</strong></th>
        <th class="mat-header-cell"><strong>Address</strong></th>
        <th class="mat-header-cell"><strong>Product</strong></th>
        <th class="mat-header-cell"><strong>Price</strong></th>
        <th class="mat-header-cell"><strong>Your Revenue</strong></th>
        <th class="mat-header-cell"><strong>Updated On</strong></th>
      </tr>
      </thead>
      <tbody>
      <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
        <td class="mat-cell">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>
        <td class="mat-cell">
          <ngx-skeleton-loader count="1" [theme]="{ width: '250px', height: '20px' }"></ngx-skeleton-loader>
        </td>
        <td class="mat-cell">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>
        <td class="mat-cell txt--right">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>
        <td class="mat-cell txt--right">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>
        <td class="mat-cell">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>
      </tr>
      </tbody>
    </table>
  </section>
</ng-container>

