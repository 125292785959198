// dep
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

// app
import { environment as ENV } from '@environment'

const CARDS_IMAGES = {"American Express" : "american-express.jpg",
                      'Diners Club'      : 'diners-club.png',
                      'Discover'         : 'discover.png',
                      'JCB'              : 'jcb.png',
                      'MasterCard'       : 'mastercard.png',
                      'UnionPay'         : 'union-pay.png',
                      'Visa'             : 'visa.png',
                      'Unknown'          : 'unknown.png'} as const
@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient) {}

  addNewCard(token: string) {
    return this.http.post(`${ENV.billingApiUrl}/card/`, {source: token})
  }

  async fetchCards(gid? : string) : Promise<{data : any[]}> {
    if(!gid) {
      // This fetchs the cards for the user authorized under the current session,
      // the gid and stripe_account_id are sent on the headers
      // TODO: Remove this and make explicit
      return await this.http.get<{data : any[]}>(`${ENV.billingApiUrl}/card/`).toPromise()
    } else {
      return await this.http.get<{data : any[]}>(`${ENV.billingApiUrl}/card/by-gid/${gid}`).toPromise()
    }
  }

  setDefaultCard(cardId : string) {
    // Card belongs to the current authorized user
    return this.http.post(`${ENV.billingApiUrl}/card/default/${cardId}`, {}).toPromise()
  }

  deleteCard(cardId: string) {
    // Card belongs to the current authorized user
    return this.http.delete(`${ENV.billingApiUrl}/card/${cardId}`)
  }


  static getCardBrandImage(brand : string) : string {
    return "/assets/images/cards/" + (CARDS_IMAGES[brand] || CARDS_IMAGES['Unknown'])
  }

}
