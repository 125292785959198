import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-skeletons',
  templateUrl: './skeletons.component.html',
  styleUrls: ['./skeletons.component.scss']
})
export class SkeletonsComponent implements OnInit {
  public isSuperAdmin;

  @Input() visibleSection = 'settings';
  @Input() activeTab = 0;

  constructor(
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = this.auth.session.isSuperAdmin;
  }

}
