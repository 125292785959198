import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { BehaviorSubject, Observable } from "rxjs";
import {
  GRADE_LEADS, LOCATIONS
} from "../constants/firestore/collections";
import { FirestoreService } from "./firestore.service";
import { Pageable } from "../constants/pageable";
import GradeLead from "../constants/firestore/grade-leads";
import { HttpClient } from "@angular/common/http";
import { environment } from '@environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})


export class GradeLeadService {
  public loading = false;
  private _gradeLeads: BehaviorSubject<GradeLead[]> = null;

  constructor(
    private afs: AngularFirestore,
    private fs: FirestoreService,
    private http: HttpClient,
  ) {
    this._gradeLeads = new BehaviorSubject([]) as BehaviorSubject<GradeLead[]>;
  }

  getAllDomains() {
    return this.afs.collectionGroup<any>(GRADE_LEADS).snapshotChanges()
      .pipe(map(snaps => snaps.map(snap => snap.payload.doc.data())));
  }

  getAllGradeLeads(domain: string) {
    return  this.afs.collection(GRADE_LEADS).doc(domain).collection(LOCATIONS).valueChanges()
  }

  getGradeLeads(pageable?: Pageable, filter?: { domain: string, viewed: boolean}, next?, prev?, order = 'createdAt', direction = 'desc', values?): Observable<any> {
    return this.fs.paginateGrades(GRADE_LEADS, filter, order, direction, pageable, next, prev, values);
  }

  set(gradeLeads: GradeLead[]) {
    this._gradeLeads.next(gradeLeads);
  }

  getGradeLeadPaginate(count, pageable, actions) {
    return this.fs.formatPagination(count, pageable, actions);
  }

  /**
   * this method save in firestore entity Grade Lead
   * @param GradeLead
   */
  save(gradeLead: GradeLead) {
    return this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).get().toPromise().then( domainCollection => {
              if ( !domainCollection.exists ) {
                this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).set({ domain: gradeLead.registrationDomain });
              }
              this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).collection(LOCATIONS).add(gradeLead)
            });
  }

  update(gradeLead: GradeLead) {
    return this.afs.collection(GRADE_LEADS).doc(gradeLead.registrationDomain).collection(LOCATIONS).doc(gradeLead.id).set(gradeLead)
  }

  downloadExternalGradePDF(grade_lead_id: string): Observable<any> {
    const data = {
      grade_lead_id
    }

    return this.http.post(`${environment.apiUrl}/v2/pdf/download`, data);
  }

}
