<div class="view-logs">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h2 class="m--0"><strong>View Logs</strong></h2>
  </div>

  <app-skeletons [visibleSection]="'view-logs'" *ngIf="loading"></app-skeletons >

  <div class="view-logs-table" *ngIf="!loading">
    <p-table #dt [value]="dataSource" [loading]="tableLoading" [columns]="cols" styleClass="p-datatable" [rowHover]="true"
    [responsive]="true" [scrollable]="true"
    [paginator]="false" [filterDelay]="0">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="input-field--search filter-table">
            <section class="table-actions">
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white"(click)="clearTable(dt)">
                <i class="pi pi-filter-slash"></i>
              </button>
            </section>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of cols">
            <th [class.th-width-s]="col.hasWidth">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{col.header | titlecase}}
                <p-columnFilter *ngIf="col.field === 'domain' || col.field === 'type_log'" field="{{col.field}}" matchMode="in" display="menu">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown *ngIf="col.field === 'domain'" [ngModel]="value" [options]="domainsOptions" placeholder="Domains" (onChange)="filterChange(col.field, $event.value)"></p-dropdown>
                    <p-dropdown *ngIf="col.field === 'type_log'" [ngModel]="value" [options]="typelogOptions" placeholder="Type Logs" (onChange)="filterChange(col.field, $event.value)"></p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dataSource>
        <tr class="p-selectable-row">
          <td *ngIf="auth.session.isSuperAdmin"><span [outerHTML]="dataSource?.domain | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.gid | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.type_log | titlecase | isEmpty"></span> </td>
          <td><span [outerHTML]="dataSource?.message | titlecase | isEmpty"></span> </td>
          <td class="align-center"><span [outerHTML]="dataSource?.created | date: 'MM/dd/yy' | isEmpty"></span> </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No users found.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator [rows]="paginate.size" [totalRecords]="pagination?.total" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
    currentPageReportTemplate="{{pagination?.total}} entries" (onPageChange)="pageChange($event)"></p-paginator>
  </div>
</div>
