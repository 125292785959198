import {environment as defaultEnvironment} from './environment.default';

export const environment = {
  ...defaultEnvironment,
  env: 'prod',
  production: true,

  // core-api
  // coreApiUrl: 'https://core-api-ccx5mqu6oa-ue.a.run.app',

  // post-api
  apiUrlV3: 'https://api-ccx5mqu6oa-ue.a.run.app/api/v3',

  // billing-api
  billingApiUrl: 'https://billing-api-ccx5mqu6oa-ue.a.run.app/api',

  // main-api
  apiUrl: 'https://main-api.maplabs.com/api',  

};
