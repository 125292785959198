// dep
import { Injectable } from '@angular/core'
import { AngularFirestore, CollectionReference } from '@angular/fire/compat/firestore'
import { HttpClient } from '@angular/common/http'
import {BehaviorSubject, Observable} from 'rxjs'
import _ from 'lodash'

// app
import { WhiteLabelService } from './white-label.service'
import { environment as ENV } from 'src/environments/environment'
import { DOMAINS } from '../constants/firestore/collections'
import Domain from '../constants/firestore/domain'

export type DomainsSummary = { domains : {domain : string,
                                          ultimate : number, 
                                          basic : number, 
                                          essential: number}[],
                               total : number }
@Injectable({
  providedIn: 'root'
})
export class DomainService {
  ref: CollectionReference
  domain$      = new BehaviorSubject<any>(null)
  domains$     = new BehaviorSubject<any>([])

  constructor(
    private afs: AngularFirestore,
    private wl: WhiteLabelService,
    private http: HttpClient,
  ) {
    this.ref = this.collection().ref;
  }

  private collection() {
    return this.afs.collection<Domain>(DOMAINS)
  }

  getByDocId(xdomain: string) {
    return this.collection().doc(xdomain);
  }

  getDomains(): Observable<DomainsSummary> {
    // return this.http.get(`${ENV.apiUrl}/v2/subscriptions/domains`); // before MAP-1974
    return this.http.get<DomainsSummary>(`${ENV.billingApiUrl}/domain/all/summary`)
  }

  getOptionsDomains(domains) {
    return ([...new Set(domains.map(el => el.domain))]
            .filter(el => el != null && el != undefined)
            .sort())
  }

  async updateDomain(docId: string, data: object) {
    await this.ref.doc(docId).update(data);
  }

  searchIndex() {
    return this.getByDocId(this.wl.slugDomain).valueChanges()
  }

  // TODO: Unused, remove.
  // getDomainsByGroup(domain : string): Observable<any> {
  //   return this.http.get(`${ENV.apiUrl}/v2/groups/report/domain?domain=${domain}`);
  // }

}
