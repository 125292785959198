// dep
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

// app
import { environment as ENV } from 'src/environments/environment'

@Component({
  selector: 'app-export-invoice-pdf',
  template: '<p>Downloading...</p>',
})
export class ExportInvoicePdfComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    const { id } = this._route.snapshot.params;
    if(id) {
      window.location.href = `${ENV.billingApiUrl}/invoice/${id}/pdf?inline=1`;
    }
  }

}
