// dep
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import jsPDF from "jspdf"
import autoTable from 'jspdf-autotable'

// app
import { Pageable } from '../../../constants/pageable'
import { WhiteLabelService } from '../../../services/white-label.service'
import { AuthService } from '../../../services/auth.service'
import { InvoiceService } from '../../../services/invoice.service'
import { catchError, map } from 'rxjs/operators'
import { SnackbarService } from 'src/app/services/snackbar.service'
import { throwError } from 'rxjs'
import { DatePipe } from '@angular/common'

// TODO: unused, remove
// interface jsAuto {
//   columns: { dataKey: number, header: any }[],
//   data: [{ colSpan: number, content: string, rowSpan: number, styles: any }][]
// }

@Component({
  selector: 'app-invoices-details',
  templateUrl: './invoices-details.component.html',
  styleUrls:  ['./invoices-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {
  public invoice;
  public previousPage : string;
  public loading = true;
  public dataSource = [];
  public cols = [
    { header: 'Description', field: 'description', isCurrency : false },
    { header: 'Price', field: 'productPrice', isCurrency: true },
    { header: 'Discount', field: 'discountRate', isCurrency: true },
    { header: 'Map Labs Platform Fee', field: 'productFee', isCurrency: true },
  ] as const

  public paginate: Pageable = { page: 1, size: 10 };
  public isSuperAdmin : boolean;
  public domainValue : string;
  public filterField;
  public retriesMessages = '';
  public invoiceId : string;
  public invHasPdf = false;

  private invoiceGID : string;
  public isDraft : boolean;

  constructor(
    private router: Router,
    private wl: WhiteLabelService,
    private auth: AuthService,
    private invoiceS: InvoiceService,
    private snack: SnackbarService,
    private datePipe: DatePipe
  ) {
    this.isSuperAdmin = this.auth.session.isSuperAdmin;
  }

  ngOnInit(): void {
    const inv         = history.state?.invoice
    this.invoiceGID   = history.state?.gid

    this.previousPage = inv?.previousPage
    this.domainValue  = inv?.domain
    this.filterField  = inv?.filterField
    this.invoiceId    = inv?.id
    this.isDraft      = inv?.isDraft
  
    this.fetchInvoice();
  }

  async fetchInvoice(): Promise<void> {
    try {
      const inv = (this.isDraft ? 
                   (await this.invoiceS.fetchInvoiceNextMonthDraft(this.invoiceGID, false)).invoice :
                   (await this.invoiceS.getInvoiceById(this.invoiceId).toPromise()).data);
      this.invoice          = inv;
      this.invHasPdf        = !!inv.invoicePDF?.file; 
      inv.displayName       = inv.user?.displayName;
      inv.email             = inv.user?.email;
      inv.created           = inv.createdAt?.$date;
      inv.updated           = inv.updatedAt?.$date;
      inv.subscriptionCount = this.invoiceS.getSubscriptionCount(inv);

      this.dataSource       = inv.items;

      this.retriesMessages = this.isDraft ? '' : await this.fetchRetriesMessages();
    } catch (e) {
      console.error(`Error loading invoice ${this.invoiceId}:`, e);
    } finally {
      this.loading = false;
    }
  }

  private async fetchRetriesMessages(): Promise<any> {
    const r = await this.invoiceS.getInvoiceLogs(this.invoiceId).toPromise();
    const messages = r.data.filter(el => el?.type == 'ERROR')
      .map((el, i) => {
        const error = el?.stripeRequestError || el?.stripeMessage?.data?.object.last_payment_error?.message;
        return `Retry ${i + 1}: ${error}`
      })
    return messages.join(" | ");
  }

  // TODO: Refactor against invoices.component.ts/exportPdf
  exportPdf(table): void {
    const data = table.filteredValue || this.dataSource;
    const exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    const doc = new jsPDF('p', 'pt', [window.innerWidth, window.innerHeight]);

    const body = data.map(r => {
      const row = {}
      for (const col of this.cols)
        row[col.field] = col.isCurrency ? InvoiceService.formatPrice(r[col.field]) : r[col.field]
      return row
    })

    autoTable(doc, { columns: exportColumns, body })
    doc.save("invoice-products.pdf");
  }

  downloadInvoicePdf(): void {
    this.invoiceS.fetchInvoicePDF(this.invoiceId)
    .pipe(
      catchError(error => {
        if (error.status === 404) {
          this.snack.openError('The resource you are trying to download doesn\'t exist. Please use the "Print PDF from screen" button', 3000);
        } else {
          this.snack.openError('There was an error when attempting to download the invoice PDF file. Please use the "Print PDF from screen" button', 3000);

        }
        return throwError(error);
      }),
      map(response => {
        if (!response) {
          this.snack.openError('There was an error when attempting to download the invoice PDF file. Please use the "Print PDF from screen" button', 3000);
          return null;
        }
        return response;
      })).subscribe(response => {
        if (response) {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          
          const link = document.createElement('a');
          link.href = url;
          const formattedDate = this.datePipe.transform(this.invoice.createdAt?.$date || 0, 'yyyy-MM-dd');
          
          link.download = `${formattedDate}_${this.invoiceId}_invoice.pdf`;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      }, error => {
        this.snack.openError('There was an error when attempting to download the invoice PDF file.', 3000);
      })
  }

  pageChange(event): void {
    this.paginate = {
      page: event.first,
      size: event.rows
    }
  }

  clearTable(table): void {
    table.clear();
  }

  goDetails(): void {
    sessionStorage.goGroups = `admin/group/${this.invoice.user.uid}`;
    sessionStorage.gid      = this.invoice.gid;
    window.open(`admin/`, '_blank');
  }

  getInvoiceType(type): any {
    return type?.split('_')?.join(' ');
  }

  async goBack(tabIndex = 3): Promise<void> {
    const url = (this.previousPage === 'invoices' && tabIndex == 3 ?
                 `admin/${this.previousPage}` :
                 `admin/group/${this.invoice.user.uid}`)

    await this.router.navigate(
      [url],
      {
        state: {
          'tabIndex': tabIndex,
          'gid': this.invoice.gid,
          'filterField': this.filterField,
          'domainValue': this.domainValue
        }
      }
    )
  }
  
}
