import { Component, OnInit } from '@angular/core';

import { DomainService } from 'src/app/services/domain.service';
import { AuthService } from 'src/app/services/auth.service';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isSuperAdmin;
  public domain;

  constructor(
    public domainService: DomainService,
    public auth: AuthService,
    public groupService: GroupService
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = this.auth.session.isSuperAdmin;

    if (!this.isSuperAdmin) {
      this.groupService.getByDocId(this.auth.session.gid).subscribe(res => {
        this.domain = res.domain
      });
    }
  }


  onLogout() {
    this.auth.signOut(true, true)
  }
  
}
