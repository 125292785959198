<div class="products">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h2 class="m--0"><strong>Products</strong></h2>
  </div>

  <app-skeletons [visibleSection]="'products'" *ngIf="loading"></app-skeletons>

  <div class="products-table" *ngIf="!loading">
    <p-table #dt [value]="dataSource" [columns]="cols" [loading]="tableLoading" styleClass="p-datatable" [rowHover]="true"
      [responsive]="true" [paginator]="true" [rows]="100" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10,25,50,100]" dataKey="id"
      currentPageReportTemplate="{{dt.filteredValue?.length || dataSource?.length}} entries"
      (onPage)="pageChange($event)" [filterDelay]="0" [globalFilterFields]="['name', 'description', 'price', 'updated', 'domain']">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="input-field--search filter-table">
            <section class="table-actions">
              <button *ngIf="isSuperAdmin" mat-flat-button color="primary" class="btn btn-icon" matTooltip="Add Product"
                matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="openModal('add')">
                <i class="pi pi-plus"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort"
                matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="clearTable(dt)">
                <i class="pi pi-filter-slash"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="dt.exportCSV()">
                <i class="pi pi-file"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF"
                matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf(dt)">
                <i class="pi pi-file-pdf"></i>
              </button>
              <p-dropdown *ngIf="domainsOptions.length > 1" 
                           class="domains-filter" 
                           [showClear]="true" 
                           [(ngModel)]="domainValue" 
                           [options]="domainsOptions" 
                           placeholder="Domains" 
                           (onChange)="filterDomain(dt, $event.value)">
              </p-dropdown>
            </section>
            <section class="table-filter">
              <div class="table__icon table__icon--lg">
                <i class="fas fa-search"></i>
              </div>
            </section>
            <input pInputText type="text" [ngModel]="filterField"
              (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Type to search" />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of cols">
            <th pSortableColumn="{{col?.field}}" [class.is-currency]="col.isCurrency">
              <div class="p-d-flex p-jc-between p-ai-center" [class.txt--right]="col?.field === 'price'" [class.align-right-table]="col?.field === 'platformFee'">
                {{col.header | titlecase}}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>
                <p-columnFilter *ngIf="col.field === 'name' || col.field === 'description'" type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                <p-columnFilter *ngIf="col.field === 'updated'" type="date" field="{{col.field}}" display="menu"></p-columnFilter>
                <p-columnFilter *ngIf="col.field === 'price' || col.field === 'platformFee'" type="numeric" field="{{col.field}}" display="menu" currency="USD"></p-columnFilter>
              </div>
            </th>
          </ng-container>
          <th>
            <div class="txt--right">Actions</div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dataSource>
        <tr class="p-selectable-row">
          <td><span [outerHTML]="dataSource.name | titlecase | isEmpty"></span></td>
          <td><span [outerHTML]="(dataSource.description | titlecase | isEmpty)"></span></td>
          <td class="td-width-xm" *ngIf="isSuperAdmin"><span [outerHTML]="(dataSource.domain | isEmpty)"></span></td>
          <td class="align-right-table"><span [outerHTML]="(dataSource.price | currency | isEmpty)"></span></td>
          <td class="align-right-table td-width-sm"><span [outerHTML]="(dataSource.platformFee | currency | isEmpty)"></span></td>
          <td><span [outerHTML]="(dataSource.updated | date: 'MM/dd/yy' | isEmpty)"></span></td>
          <td class="align-right-table">
            <span>
              <button mat-flat-button color="primary" class="btn btn-icon" (click)="openModal('edit', dataSource, dt)">
                <i class="far fa-pencil"></i>
              </button>
            </span>
        </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No products found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
