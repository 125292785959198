import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss']
})
export class ModalDeleteComponent implements OnInit {
  @Output() deleted: EventEmitter<boolean> = new EventEmitter();
  status: 'init' | 'pending' | 'completed' = 'init';
  message: string = 'This action cannot be undone!';
  name: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.name = this.data?.data?.name;
  }

  ngOnInit() {
    // This is intentional
  }

  complete() {
    this.message = 'The action was completed successfully';
    this.status = 'completed';
  }

  delete() {
    this.status = 'pending';
    this.deleted.emit(true)
  }

}
