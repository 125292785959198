<div class="grade-leads">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h2 class="m--0"><strong>Grader Leads</strong></h2>
  </div>

  <app-skeletons [visibleSection]="'grade-leads'" *ngIf="loading"></app-skeletons>
  <div class="view-logs-table" *ngIf="!loading">
    <p-table #dt [value]="dataSource" [loading]="tableLoading" [columns]="cols" [rows]="paginate.size" styleClass="p-datatable" [rowHover]="true"
    [responsive]="true"
    [paginator]="true" [rows]="paginate.size" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
    currentPageReportTemplate="{{dt.filteredValue?.length || dataSource?.length}} entries"
    [filterDelay]="0" [globalFilterFields]="['accountName', 'locationName', 'email', 'registrationDomain', 'created', 'viewed']">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="input-field--search filter-table">
            <section class="table-actions">
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white"(click)="clearTable(dt)">
                <i class="pi pi-filter-slash"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="dt.exportCSV()">
                <i class="pi pi-file"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf(dt)">
                <i class="pi pi-file-pdf"></i>
              </button>
              <p-dropdown class="viewed-filter" [(ngModel)]="viewedValue" [options]="viewedOptions" placeholder="Filter by read/unread" (onChange)="filterDomain(dt, viewedValue)">
              </p-dropdown>
            </section>
            <section class="table-filter">
              <div class="table__icon table__icon--lg">
                <i class="fas fa-search"></i>
              </div>
            </section>
            <input pInputText type="text" [ngModel]="filterField" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Type to search" />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of cols">
            <th pSortableColumn="{{col?.field}}" [class.is-currency]="col.isCurrency">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{col.header | titlecase}}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>
                <p-columnFilter *ngIf="col.field !== 'registrationDomain' && col.field !=='created'" type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                <p-columnFilter *ngIf="col.field === 'created'" type="date" field="{{col.field}}" display="menu"></p-columnFilter>
                <p-columnFilter *ngIf="col.field === 'registrationDomain'" field="{{col.field}}" matchMode="in" display="menu">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-dropdown [ngModel]="value" [options]="domainsOptions" placeholder="Domains" (onChange)="filter($event.value)">
                      </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
          </ng-container>
          <th>
            <div class="txt--right">Actions</div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dataSource>
        <tr class="p-selectable-row">
          <td class="td-width-sm"><span [outerHTML]="dataSource?.accountName | titlecase | isEmpty"></span></td>
          <td class="td-width-sm"><span [outerHTML]="dataSource?.locationName | titlecase | titlecase | isEmpty"></span></td>
          <td class="td-width-xxm"><span [outerHTML]="dataSource?.email | titlecase | isEmpty"></span></td>
          <td *ngIf="isSuperAdmin" class="td-width-sm"><span [outerHTML]="dataSource?.registrationDomain | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.created | date: 'MM/dd/yy' | isEmpty"></span></td>
          <td class="align-right-table">
            <span [class]="dataSource.viewed ? '' : 'alternate-theme'">
              <button mat-flat-button color="primary" class="btn btn-icon" (click)="viewedGradeLead(dataSource)">
                <i class="far fa-eye"></i>
              </button>
            </span>
            <button mat-flat-button color="primary" class="btn btn-icon" (click)="loadGradeLead(dataSource)">
              <i class="far fa-check"></i>
            </button>
            <button *ngIf="dataSource.hasPdf"
              mat-flat-button color="primary" 
              class="btn btn-icon" 
              (click)="downloadPdf(dataSource)"
            >
              <i class="far fa-file-pdf"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No grader leads found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
