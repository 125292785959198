import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty',
})
export class isEmptyPipe implements PipeTransform {

  transform(value: string) {
    return (value == '' || value == null) ? '<span class="isEmpty">-</span>' : value;
  }

}
