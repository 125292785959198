import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'app-color-date-picker',
  templateUrl: './color-date-picker.component.html',
  styleUrls: ['./color-date-picker.component.scss']
})
export class ColorDatePickerComponent implements OnInit, OnChanges {
  @Input() color: any;
  @Input() masterReset;
  @Output() selected = new EventEmitter();
  @Output() cleared = new EventEmitter();

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
  }

  handleSelected($event) {
    this.selected.emit($event);
  }

  handleCleared() {
    this.color = undefined;
    this.cleared.emit();
  }

  ngOnChanges() {
    if (this.masterReset !== 0) {
      this.color = undefined;
      this.cleared.emit();
    }
  }

}
