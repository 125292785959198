<div class="login content--full p-tb--20 bg--darkblue d-flex justify-content-center align-items-center flex-column"
	 [ngStyle]="{'background-color':  env?.branding?.loginColor  ? env?.branding?.loginColor : env?.branding?.primaryColor }">

	<!--loader-->
	<div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="loginProgress">
		<mat-spinner></mat-spinner>
	</div>

	<div class="logo mb--20 mt--20">
		<a mat-button>
			<img alt="Logo" src="{{ env?.branding?.mainLogo || '/assets/images/logo/blankmap.png' }}" width="170"
				 height="59"/>
		</a>
	</div>

	<!-- Interim login implementation -->
	<div class="box box--md box--padding-md box--rounded bg--white mb--20">
		<form [formGroup]="loginForm" (submit)="signInWithEmailAndPassword($event)">

			<h1 class="txt--xl mb--10 txt--center"><strong>Log in</strong></h1>
      <!--login with google button-->
      <button mat-raised-button class="btn btn--xxl btn--full btn--img mb--20 mt--20" (click)="signInWithGoogle($event)">
        <img src="/assets/images/icons/google-color.png" alt="Google Logo"> <strong>Log In With Google</strong>
      </button>

      <div class="line-through txt--center txt--uppercase"><span class="txt--normal">or</span></div>
      <p class="txt--center">Enter your details below to access your account:</p>

			<!--email field-->
			<mat-form-field hideRequiredMarker class="input-group input-group--override">
				<input matInput placeholder="Email" name="email" type="email" formControlName="email"
					   class="input-group__field input--email" required>
				<mat-error *ngIf="loginForm.controls.email.invalid && loginForm.controls.email.touched"><p>Please enter
					valid email</p></mat-error>
			</mat-form-field>

			<!--password field-->
			<mat-form-field hideRequiredMarker class="input-group input-group--override">
				<input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" name="password"
					   type="password" formControlName="password" class="input-group__field input--password" required>
				<mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched"><p>Please
					enter valid password</p></mat-error>
			</mat-form-field>

			<!--remember me and forgot password-->
			<div class="d-flex align-items-center justify-content-between">
				<mat-checkbox class="txt--normal">Remember me</mat-checkbox>
				<a href="" class="txt--normal" [routerLink]="['/forgot-password']">Forgot password?</a>
			</div>

			<!--log in button-->
			<button mat-raised-button color="primary" class="btn btn--xxl btn--full mb--20"
					[disabled]="loginForm.invalid"><strong>Log In</strong></button>
		</form>
	</div>

	<div class="footer footer--noborder flex-column box box--md txt--center">
		<ul class="footer__nav d-flex justify-content-center mb--20">
			<li><a class="txt--white" (click)="goToTermsOfService()">Terms and Conditions</a></li>
			<li><a class="txt--white" (click)="goToPrivacyPolicy()">Privacy Policy</a></li>
			<li><a class="txt--white" (click)="goToPaymentPolicy()">Payment Policy</a></li>
		</ul>
		<p class="txt--normal txt--gray m--0">&copy; {{ env?.branding?.company_name }} All Rights Reserved.</p>
	</div>
</div>
