import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/constants/api-response';
import { Pageable } from 'src/app/constants/pageable';
import { AuthService } from 'src/app/services/auth.service';
import { DomainService } from 'src/app/services/domain.service';
import { LogsService } from 'src/app/services/logs.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({
  selector: 'app-view-logs',
  templateUrl: './view-logs.component.html',
  styleUrls: ['./view-logs.component.scss']
})
export class ViewLogsComponent implements OnInit {
  public loading = true;
  public dataSource = [];
  public cols = [
    { header: 'Domain', field: 'domain' },
    { header: 'GID', field: 'gid' },
    { header: 'Type Log', field: 'type_log', hasWidth: true },
    { header: 'Message', field: 'message' },
    { header: 'Created On', field: 'created' }
  ];
  public paginate: Pageable = {page: 1, size: 10};
  public previousPageable: { size: any; page: any };
  public pagination: Pagination;
  /* public progress = true; */
  public filter = { domain: null, type_log: null};
  public domain: string;
  public domains$;
  public domainsOptions = [];
  public typelogOptions = [];
  public totalRecords = 0;
  public tableLoading = false;

  constructor(
    public auth: AuthService,
    private wlService: WhiteLabelService,
    private domainService: DomainService,
    private logsService: LogsService,
  ) { }

  ngOnInit(): void {
    if (!this.auth.session.isSuperAdmin) {
      this.cols.splice(0, 1)
    }
    this.domain = this.auth.session.isSuperAdmin ? 'all' : this.wlService.baseDomain;
    this.domainService.getDomains().subscribe(
      res => {
        this.domains$ = res;
        this.domainsOptions =  this.domainService.getOptionsDomains(res?.domains);
        this.filterByDomain({ value: this.domain });
      }
    );
  }

  filterByDomain({ value }): void {
    this.initPaginator();
    this.filter.domain = null;
    if ("all" !== value) {
      const domain = ("localhost" === value && "localhost:4200") || value.replace(/\_/g, ".");
      this.filter.domain = domain;
    }
    this.getData(this.filter);
  }

  getData(filter: { domain: string, type_log: string} = null) {
    this.typelogOptions = [];
    this.logsService.getLogs(this.paginate, filter, this.auth.session.gid, this.auth.session.uid).subscribe( result => {
      result.items.forEach(l => {
        l.created = new Date(l.create_at.$date);
      });
      this.getTypeLogs(result.items);
      this.previousPageable = {size: result.per_page, page: result.page};
      this.pagination = result;
      this.dataSource = result.items;
      this.loading = false
      this.tableLoading = false;
    })
  }


  getTypeLogs(results) {
    const typelog = results.map(el => el.type_log);
    const typelogSet = [...new   Set(typelog)];
    this.typelogOptions = typelogSet.filter(el => el != null && el != undefined);
  }

  initPaginator(): void {
    this.paginate = { page: 1, size: 10 };
    this.previousPageable = null;
  }

  filterChange(filter, value) {
    this.loading = true;
    this.filter[filter] = value;
    this.getData(this.filter);
  }

  pageChange(event) {
    this.tableLoading = true;
    this.paginate = {
      page: event.page + 1,
      size: event.rows
    }
    this.getData(this.filter);
  }

  clearTable(table) {
    this.loading = true;
    table.clear();
    this.filter = {
      domain: this.auth.session.isSuperAdmin ? null : this.filter.domain,
      type_log: null
    };
    this.getData();
  }

  exportPdf(table) {
    const data = table.filteredValue || this.dataSource;
    const exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    const doc = new jsPDF('p','pt', [window.innerWidth, window.innerHeight]);
    doc['autoTable'](
      exportColumns,
      data,
      {
        columnStyles: {
          0: {columnWidth: 120},
          1: {columnWidth: 120}
      }
    });
    doc.save("view-logs.pdf");
  }
}
