// dep
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Sort } from '../constants/sort'
import { Pageable } from '../constants/pageable'

// app
import { environment as ENV } from '@environment'
import Subscription, { SubscriptionDetailed } from '../constants/subscription'

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private _http: HttpClient) {}

  fetchSubscription(gid : string): Promise<{data : SubscriptionDetailed}> {
    return this._http.get<{data : SubscriptionDetailed}>(`${ENV.billingApiUrl}/subscription/${gid}`).toPromise()
  }

  fetchSubscriptionsAll(): Observable<{data : Subscription[]}> {
    return this._http.get<{data : Subscription[]}>(`${ENV.billingApiUrl}/subscription/all`)
  }

  getSubscriptionsPaginated(
    paginate: Pageable, 
    sort: Sort,
    domain: string,
    inputSearch: string,
    statusFilter: string
    ): Observable<any> {
      const reverse = sort.direction === "desc"? 1 : 0
      let url = `${ENV.billingApiUrl}/subscription/all-paginated?page=${paginate.page}&pageSize=${paginate.size}&sortKey=${sort.sortBy}&reverse=${reverse}`
      if (domain){
        url += `&domain=${domain}`
      }
      if (inputSearch){
        url += `&query=${inputSearch}`
      }
      if (statusFilter){
        url += `&status=${statusFilter}`
      }
      return this._http.get(url)
  }

  changeBillingOverride(gid : string, billing_override_enabled : boolean): Promise<any> {
    const body = {
      'billingOverride': billing_override_enabled
    }
    return this._http.put(`${ENV.billingApiUrl}/subscription/${gid}/billing-override`, body).toPromise();
  }

  async changeStatus(gid : string, newStatus : { status : 'BLOCKED' | 'ACTIVE' } | 
                                               { status : 'TRIAL', trialEnd : string }) : Promise<'ERROR_UNKNOWN' | 
                                                                                                  'ERROR_HAS_UNPAID_INVOICES' | 
                                                                                                  'ERROR_NOT_AUTHORIZED' | null> {
    try {
      await this._http.put<void>(`${ENV.billingApiUrl}/subscription/${gid}/status`, newStatus).toPromise<any>();
      return null
    } catch(e) {
      return e.error?.detail?.message || 'ERROR_UNKNOWN';
    }
  }

}
