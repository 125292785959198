<div class="dashboard-admin">
  <app-header></app-header>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation" mode="side" disableClose="true" opened="true" class="d-print-none" [fixedInViewport]="true">
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <main class="content-header">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
