<mat-toolbar class="header d-flex justify-content-between bg--white p--0">

	<div class="header__aside d-flex justify-content-between align-items-center"
		 [ngStyle]="{'background-color': (domainService.domain$ | async)?.branding?.secondaryColor || ''}">
		<div class="logo">
			<a mat-button>
				<img alt="Logo"
					 src="{{ (domainService.domain$ | async)?.branding?.mainLogo || '/assets/images/logo/blankmap.png' }}"
					 class="logo"
					 height="50" />
			</a>
		</div>
	</div>

  <div class="d-flex align-content-start user-domain">
    <div class="menu d-flex">
      <p><span class="d-block" *ngIf="auth.session && !isSuperAdmin"><strong>{{domain}}</strong></span></p>
    </div>
  </div>

  <div class="d-flex align-content-end user-name">
    <div class="menu d-flex">
      <div class="menu__item menu__item--profile">
        <div *ngIf="auth.session"
            class="profile d-flex align-items-center justify-content-between">
          <div class="profile__img">
            <img default="/assets/images/unknown-avatar.png"
                src="{{ auth.session.photoURL || '/assets/images/unknown-avatar.png' }}"
                alt="{{auth.session.displayName}}"
                width="40" height="40"
                class="d-block"
            >
          </div>
          <div class="profile__txt d-flex flex-column txt--left">
            <p class="mb--5">Hi,
              <span class="d-block"><strong>{{auth.session.displayName}}</strong></span>
            </p>
          </div>
        </div>
      </div>
      <div class="menu__item--fullwidth">
        <button (click)="onLogout()" mat-button color="primary">
          <img src="../../../assets/images/icons/logout.svg" class="menu__item__icon">
        </button>
      </div>
    </div>
  </div>
</mat-toolbar>

