// dep
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Pageable } from '../constants/pageable'
import { Sort } from '../constants/sort'
import jsPDF from "jspdf"
import "jspdf-autotable"

// app
import { environment as ENV } from '@environment'

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  public readonly cols = [
    { header: 'Description',  field: 'description' },
    { header: 'Price',        field: 'price',    isCurrency: true },
    { header: 'Discount',     field: 'discount', isCurrency: true },
    { header: 'Platform Fee', field: 'fee',      isCurrency: true }
  ] as const;

  constructor(private http: HttpClient) {}

  getInvoices(gid? : string): Observable<any> {
    if (gid) {
      return this.http.get(`${ENV.billingApiUrl}/invoice/gid/${gid}?summary=1`)
    } else {
      return this.http.get(`${ENV.billingApiUrl}/invoice/all?summary=1`)
    }
  }

  getInvoicesPaginated(
    paginate: Pageable, 
    sort: Sort,
    domain: string,
    inputSearch: string,
    status: string
    ): Observable<any> {
      const reverse = sort.direction === "asc"? 0 : 1
      let url = `${ENV.billingApiUrl}/invoice/all-paginated?summary=1&page=${paginate?.page}&pageSize=${paginate?.size}&sortKey=${sort?.sortBy}&reverse=${reverse}`
      if (domain){
        url += `&domain=${domain}`
      }
      if (inputSearch){
        url += `&query=${inputSearch}`
      }
      if (status){
        url += `&status=${status}`
      }
      return this.http.get(url)
  }

  getInvoiceById(id : string): Observable<any> {
    return this.http.get(`${ENV.billingApiUrl}/invoice/${id}?expanded=1`);
  }

  getInvoiceLogs(id : string): Observable<any> {
    return this.http.get(`${ENV.billingApiUrl}/invoice/${id}/logs`);
  }

  async fetchInvoiceNextMonthDraft(gid : string, summary = false) : Promise<any> {
    // &pricingVersion=2
    return (await this.http.get(`${ENV.billingApiUrl}/invoice/subscription/draft/${gid}?summary=${summary ? '1' : '0'}`).toPromise() as any).data
  }

  getSubscriptionCount(invoice) : { [pid : string] : number } {
    // TODO: Check if possible to use items.product instead of parsing item description (safer),
    // and use lowercase pids as keys. 
    const qtys = { "Ultimate"  : 0,
                   "Basic"     : 0,
                   "Essential" : 0}
    for(const it of invoice.items) {
      const desc = it.description.toLowerCase()
      const pid  = desc.includes('basic') ? 'Basic' : (desc.includes('ultimate') ? 'Ultimate' : 'Essential')
      qtys[pid]  += 1
    }
    return qtys
  }

  static formatPrice(price : number) : string {
    return (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })).format(price)
  }

  fetchInvoicePDF(invId: string ): Observable<Blob> {
    return this.http.get(`${ENV.billingApiUrl}/invoice/${invId}/pdf`,  { responseType: 'blob' })
  }
  
  getTooltip(invoice) : string {
    const c = invoice?.locationsCount
    return ('Essential: '+`${c?.essential} | `+
            'Basic: '    +`${c?.basic} | `+
            'Ultimate: ' +`${c?.ultimate} `)
  }

}
