import User from "./firestore/user";

export interface Session extends User {
  [x: string]: any;
}

// export const SESSION = 'session'; // main-frontend session
export const SESSION = 'admin_session';

// TODO: Not used? Remove
// export const SESSION_EXTERNAL = 'session_external';
export const EXTERNAL_GRADE_ID = 'external_grade_id';

