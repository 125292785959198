import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public isSuperAdmin = false;

  constructor(
    public domainService: DomainService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = this.auth.session.isSuperAdmin;
  }

}
