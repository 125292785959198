// TODO: Remove firestore dependency replace with queries to the backend

// dep
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import _ from 'lodash';
import firebase from 'firebase/compat/app';

// app
import Group from '../constants/firestore/group';
import { GROUPS, USER } from '../constants/firestore/collections';
// import Subscription from '../constants/subscription';
import { environment as ENV } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  // TODO: Unused, remove
  // private groupSource = new BehaviorSubject<Group>(null);
  // group$ = this.groupSource.asObservable();
  // private subscriptionSource = new BehaviorSubject<Subscription>(null);
  // subscription$ = this.subscriptionSource.asObservable();

  constructor(private afs: AngularFirestore,
              private http: HttpClient) {}

  getByDocId(gid: string) : Observable<Group> {
    return this.http.get<Group>(`${ENV.apiUrl}/v2/groups/${gid}`);
  }

  updateGroup(gid : string, data: any) {
    return this.http.put(`${ENV.apiUrl}/v2/groups/${gid}`, data).toPromise();
  }

  async markUserEmailIsVerified(user: any) : Promise<void> {
    const userRef = this.afs.collection(GROUPS).doc(user.gid).collection(USER).doc(user.uid);
    await userRef.update({'emailVerified': firebase.firestore.Timestamp.now()})
  }

  // TODO: Unused, remove.
  // async groupsByUser(list) {
  //   await Promise.all(list.map(
  //     async (user) => {
  //       if (user.gid) {
  //         const group = this.getByDocId(user.gid);
  //         const asyncData = await Promise.all([group]);
  //         if (asyncData[0]) {
  //           await new Promise(resolve =>
  //             asyncData[0]
  //               .subscribe(g => {
  //                 user.locationsCount = _.get(g, 'totalLocationsCount', 0);
  //                 resolve(g);
  //               }));
  //         }
  //       } else {
  //         return;
  //       }
  //     }
  //   ));
  // }


}


