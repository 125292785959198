import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import { DomainService } from '../../services/domain.service';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements OnInit {
  public loading = true;
  public cols = [
    { header: 'Domain', field: 'domain' },
    { header: 'Essentials', field: 'essential' },
    { header: 'Basics', field: 'basic' },
    { header: 'Ultimates', field:'ultimate' },
  ];
  public domainValue;
  public dataSource = [];
  public domainsOptions = [];
  public filterField;

  constructor(
    private domainService: DomainService,
  ) { }

  ngOnInit(): void {
    this.domainService.getDomains().subscribe(
      domains => {
        this.domainsOptions = domains?.domains.map(el => el.domain);
        this.dataSource = domains.domains;
        this.removeDuplicate();
        this.loading = false;
      }
    );
  }

  removeDuplicate() {
    let domainsSet = [...new   Set(this.domainsOptions)];
    this.domainsOptions = domainsSet;
    this.domainsOptions = this.domainsOptions.sort();

    const data = []

    domainsSet.forEach(el => data.push(this.dataSource.find(d => d.domain == el)));
    this.dataSource = data;
  }

  clearTable(table) {
    this.domainValue = null;
    this.filterField = null;
    table.clear();
  }

  filterDomain(table, value) {
    table.filterGlobal(value, 'contains');
  }

  exportPdf(table) {
    const data = table.filteredValue || this.dataSource
    const exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    const doc = new jsPDF('p','pt', [window.innerWidth, window.innerHeight]);
    doc['autoTable'](exportColumns, data);
    doc.save("domains.pdf");
  }
}
