import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Observable, Observer } from 'rxjs';
import * as _ from 'lodash';

import { Messages, string_message } from '../constants/messages';
import {finalize} from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';
import { environment } from '@environment';

export type TypeImages = 'PHOTO' | 'VIDEO';

@Injectable({
  providedIn: 'root'
})
export class StorageImageService {
  fileRef: AngularFireStorageReference;
  _url: BehaviorSubject<{ url: string, preview: string, type: TypeImages, fileName: string}> = new BehaviorSubject(null);
  _ref = new BehaviorSubject<AngularFireStorageReference>(null);
  _percent = new BehaviorSubject<number>(0);
  isWebpImage: boolean;
  public _imageUrl = new BehaviorSubject(null);


  constructor(
    private afstorage: AngularFireStorage,
    private snackService: SnackbarService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
 /*    private postS: PostService, */
  ) {}

  fileChanged(event, placeId, type: TypeImages, requirements?) {
    const files = event.files;
    if (!files[0]) {
      this._url.next(null);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      type = files[i].type === 'video/mp4' ? 'VIDEO' : 'PHOTO';
      if (requirements && _.isArray(requirements.type) && !_.includes(requirements.type, files[i].type)) {

        const types = requirements.type.map(t => _.last(_.split(_.kebabCase(t), '-')));
        this.snackService.openWarning(`${Messages.upload.BAD_TYPES} ${_.toString(types)}`, 5000);
        this._url.next(null);
        return;
      } else if (requirements && !_.isArray(requirements.type) && files[i].type !== requirements.type) {
        this.snackService.openWarning(Messages.upload.BAD_TYPE, 4000);
        this._url.next(null);
        return;
      }
      if (requirements && requirements.min_size && files[i].size < requirements.min_size) {
        const msg = string_message(
          Messages.upload.BAD_SIZE_CUSTOM,
          [ requirements.min_size ]
        );
        this.snackService.openWarning(msg, 4000);
        this._url.next(null);
        return;
      }

      if (requirements && requirements.max_size && files[i].size > requirements.max_size) {
        const msg = string_message(
          Messages.upload.BAD_MAX_SIZE_CUSTOM,
          [ requirements.max_size ]
        );
        this.snackService.openWarning(msg, 5000);
        this._url.next(null);
        return;
      }

      if (type === 'PHOTO' ) {
        const img = new Image();
        img.src = window.URL.createObjectURL(files[i]);
        img.onload = () => function(file: any, self) {
          if (requirements && requirements.min_height && requirements.min_width && file.type !== 'video/mp4') {
            if (img.height < requirements.min_height || img.width < requirements.min_width) {
              const msg = string_message(
                Messages.upload.BAD_DIMENSION_MINIMUM_CUSTOM,
                [ requirements.min_width, requirements.min_height]
              );
              self.snackService.openWarning(msg, 4000);
              self._url.next(null);
              return true;
            }
          }
          if (requirements && requirements.height && requirements.width && file.type !== 'video/mp4') {
            if (img.height > requirements.height || img.width > requirements.width) {
              const msg = string_message(
                Messages.upload.BAD_DIMENSION_MINIMUM_CUSTOM,
                [ requirements.width, requirements.height]
              );
              self.snackService.openWarning(msg, 4000);
              self._url.next(null);
              return true;
            }
          } else if (img.height > 1192 || img.width > 2120) {
            self.snackService.openWarning(Messages.upload.BAD_DIMENSION,  4000);
            self._url.next(null);
            return true;
          }
          const dir = `public/${placeId}/${+new Date()}-${file.name}`;
          self.validateType(type, file.name);
          self.isWebpImage = self.verifyImageWebp(file.name);
          self.uploadFile(file, dir, 'PHOTO');

        }(files[i], this);
      } else {
        this.getFileBase64(files[i]).then( r => {
          const dir = `public/${placeId}/${+new Date()}-${files[i].name}`;
          this.validateType(type, files[i].name);
          var blob = new Blob([r], {type: files[i].type});
          var url = URL.createObjectURL(blob);

          var video = document.createElement('video') as any;
          var timeupdate = () => {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
              video.pause();
            }
          };
          video.addEventListener('loadeddata', () => {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
          });

          const snapImage = () => {
            var canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            var dataURI = canvas.toDataURL();
            var success = dataURI.length > 100000;
            if (success) {
              this.uploadFile(files[i], dir, 'VIDEO', dataURI);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener('timeupdate', timeupdate);
          video.preload = 'metadata';
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        })
      }
    }
  }


  async getFileBase64(file: File): Promise<string | ArrayBuffer> {
    const reader: FileReader = new FileReader();

    return new Promise( (resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  uploadWebpFile(file, dir, url) {
    this.transformImage(url)
      .subscribe(res => {
        const name = _.head(_.split(file.name, '.'));
        const base64Image = 'data:image/jpg;base64,' + res.data;
        const newFile  = this.dataURLtoFile(base64Image, name);
        const placeId = dir.split('/')[1];
        const newDir = `public/${placeId}/${newFile.name}`;
        this.isWebpImage = false;
        this.uploadFile(newFile, newDir, 'PHOTO');
      });
  }

  transformImage(path): Observable<any> {
    const data = { path };

    return this.http.post<any>(`${environment.apiUrl}/v2/posts/image`, data);
  }

  uploadFile(file, dir, type: TypeImages, preview: string = null) {
    const fileName = file.name;
    this.afstorage.upload(dir, file).snapshotChanges().pipe(
      finalize(() => {
        this.fileRef = this.afstorage.ref(dir);
        this._ref.next(this.fileRef);
        this.fileRef.getDownloadURL().subscribe(url => {
          const element = {
            url,
            preview,
            type,
            fileName
          }
          if(type === 'PHOTO') {
            !this.isWebpImage ? this._url.next(element) : this.uploadWebpFile(file, dir, url);
          } else {
            this._url.next(element)
          }
        });
      })
    ).subscribe();
  }


  verifyVideoFile(fileName) {
    return (fileName.includes('.mp4') || fileName.includes('.MP4')
      || fileName.includes('.avi') || fileName.includes('.AVI'));
  }

  verifyImageFile(fileName) {
    return (fileName.includes('.png') || fileName.includes('.PNG')
      || fileName.includes('.jpg') || fileName.includes('.JPG')
      || fileName.includes('.jpeg') || fileName.includes('.JPEG')
      || fileName.includes('.webp') || fileName.includes('.WEBP')
      || fileName.includes('.ico') || fileName.includes('.ICO'));
  }

  verifyImageWebp(fileName) {
    return (fileName.includes('.webp') || fileName.includes('.WEBP'));
  }

  validateType(type, name) {
    if (type === 'VIDEO') {
      // its a video
      if (!this.verifyVideoFile(name)) {
        this.snackService.openWarning('Incorrect format for a video.', 4000);
        return;
      }
    } else {
      // its a photo
      if (!this.verifyImageFile(name)) {
        this.snackService.openWarning('Incorrect format for an image.', 4000);
        return;
      }
    }
  }


  get url$() {
    return this._url.asObservable();
  }

  get url() {
    return this._url.getValue();
  }

  get ref$() {
    return this._ref.asObservable();
  }

  get percent$() {
    return this._percent.asObservable();
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  reset() {
    this._url.next(null)
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0, img.width, img.height);
    // Convert the drawn image to Data URL
    const dataURL = canvas.toDataURL('image/jpg');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const type = _.last(mime.split('/'));
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${type}`, {type: mime});
  }

  getImageUrl() {
    return this._imageUrl.asObservable();
  }

  setImageUrl(value) {
    this._imageUrl.next(value);
  }

}
