// TODO: Refactorize against main-frontend/modal.service.ts

// dep
import { Injectable } from '@angular/core';
import { ComponentType } from "@angular/cdk/portal";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

// app
import { AlertComponent, ModalAlertData, AlertType } from '../components/alert.component';
import { ConfirmComponent } from '../components/confirm.component';
import { ModalConfirmData } from "../classes/modal-confirm-data";

type MODAL_OPTS<C> = { config?       : MatDialogConfig<C>,
                       autoCloseMS?  : number,
                       disableClose? : boolean
                     }

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(
    public dialog: MatDialog
  ) {}

   async openModal<C extends { data: D, RESULT_TYPE? : R }, 
                 D, 
                 R = unknown extends C['RESULT_TYPE'] ? boolean : C['RESULT_TYPE']>
                (component  : ComponentType<C> & { OPTS_DEFAULT?: MODAL_OPTS<C> }, 
                 dialogData : C['data'],
                 opts?      : MODAL_OPTS<C> & { onClose? : (res : R) => unknown}
                ): Promise<R> {

    opts = {
           ... component?.OPTS_DEFAULT,
           ... opts, 
           ...(component?.OPTS_DEFAULT?.config || opts?.config ? 
               { config : {... component?.OPTS_DEFAULT?.config,
                           ... opts?.config }} : null)
           }

    const d = this.dialog.open(component, {... opts?.config,
                                           data : dialogData 
                                           })

    if (opts?.autoCloseMS !== undefined && opts?.autoCloseMS !== null)
      setTimeout(() => { d.close() }, opts.autoCloseMS)

    let p_resolve: (r: R) => void
    const p = new Promise<R>((resolve, /*_reject*/) => { p_resolve = resolve })

    if(opts?.disableClose !== undefined)
      d.disableClose = opts?.disableClose

    d.afterClosed().subscribe((res: R) => {
      if(opts?.onClose) 
         opts.onClose(res)
      p_resolve(res)
    })

    return p
  }


  openConfirmModal(title: string, message: string, callBackFunction?: (res:boolean)=>any, alertClass?: any, button?: string, 
                   modalPayment?: boolean, description?: string, typePaid?: boolean) : Promise<boolean> {
    return this.openModal(ConfirmComponent, new ModalConfirmData({
        title: title,
        content: message,
        confirmButtonLabel: button || 'Confirm',
        closeButtonLabel: 'Cancel',
        alertType: alertClass || null,
        modalPayment: modalPayment || false,
        description: description || null,
        typePaid: typePaid || false
      }), { config : { width: '680px'},
            onClose : callBackFunction})
  }

  async openErrorModal(title: string, message: string) : Promise<void> {
    return this.openAlertModal(title, message, AlertType.ERROR);
  }

  openAlertModal(title: string, message: string, alertType = AlertType.INFO, dismiss: number = null, 
                 closeButton = 'Ok', callBackFunction? : (res:any)=>any) : Promise<void> {
    return this.openModal(AlertComponent, new ModalAlertData({
        title,
        content: message,
        closeButtonLabel: closeButton,
        alertType
      }), { config : {  width: '680px' },
            autoCloseMS : dismiss,
            onClose : callBackFunction })
  }

  // TODO: Replace calls to this with openModal and remove it
  openGenericModal<T>(modalComponentRef: ComponentType<T>, data, callBackFunction : (res:any)=>any, modalWidth?: number): MatDialogRef<T, any> {
    const dialogRef = this.dialog.open(modalComponentRef,
      {
        width: modalWidth ? modalWidth + 'px' : '680px',
        data: new ModalConfirmData({
          data: data,
          title: null,
          content: null,
          confirmButtonLabel: 'Save',
          closeButtonLabel: 'Cancel'
        })
      });
      dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => callBackFunction(result));

    return dialogRef;
  }
}
