import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-products-edit-modal',
  templateUrl: './products-edit-modal.component.html',
  styleUrls: ['./products-edit-modal.component.scss']
})
export class ProductsEditModalComponent implements OnInit {
  public productName;
  public productDescription;
  public productPrice;
  public productPlatformFee
  public loading = true;
  public isSuperAdmin;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ProductsEditModalComponent>,
    private productsService: ProductsService
  ) { }

  ngOnInit(): void {
    if (this.data.data) {
      this.productName = this.data.data.name;
      this.productDescription = this.data.data.description
      this.formatCurrencyPrice((this.data.data.price).toString());
      this.formatCurrencyPlatformFee((this.data.data.platformFee).toString());
      this.isSuperAdmin = this.data?.isSuperAdmin;
    }
    this.loading = false;
  }

  editProduct() {
    this.loading = true;

    const data = {
      "pid": this.data.data.pid,
      "domain": this.data.data.domain,
      "name": this.productName,
      "description": this.productDescription,
      "price": this.productPrice,
      "platformFee": this.productPlatformFee,
    };

    this.productsService.editProducts(data).subscribe(
      res => {
        this.dialogRef.close(true);
      },
      err => {
        this.dialogRef.close(false);
      }
    );
  }

  formatCurrencyPrice(value) {
    value = value.indexOf('$') === -1 ? value : value.slice(1, 100);
    var uy = new Intl.NumberFormat('en-US',{style: 'currency', currency:'USD'}).format(value);
    this.productPrice = uy;
    this.cdRef.detectChanges();
  }

  formatCurrencyPlatformFee(value) {
    value = value.indexOf('$') === -1 ? value : value.slice(1, 100);
    var uy = new Intl.NumberFormat('en-US',{style: 'currency', currency:'USD'}).format(value);
    this.productPlatformFee = uy;
    this.cdRef.detectChanges();
  }

}
