import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorageReference } from "@angular/fire/compat/storage";

import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { StorageImageService, TypeImages } from '../../services/storage-image.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-form-input-upload',
  templateUrl: './form-input-upload.component.html',
  styleUrls: ['./form-input-upload.component.scss']
})
export class FormInputUploadComponent implements OnChanges, OnInit, OnDestroy {
  @Input() private placeId;
  @Input() actionText = 'post' ;
  @Input() urlInput: any = null;
  @Input() requirements: {
    accepts: string,
    type: string[],
    min_width: number,
    min_height: number,
    min_size: number,
    max_size: number,
  };
  @Input() noLoader: boolean;
  @Input() imageId = '';
  @Output() private url = new EventEmitter<{url: string, mediaFormat: string, preview: string, fileName: string}>();
  @Output() private ref = new EventEmitter<AngularFireStorageReference>();
  @Output() private percentUpload = new EventEmitter<number>();
  @Input() category: TypeImages = 'PHOTO';
  @Output() private changedImg = new EventEmitter<any>();
  @Input() isMultiple: boolean = false;

  showPreview$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  imageUrl: string;

  fileUrl: string;
  percent: number;
  isUploading: boolean = false;
  showLoader: boolean;
  subsManager$: Subject<boolean> = new Subject();
  imageUrlSubscription;

  public hasPhoto = true;

  constructor(private storageS: StorageImageService, private changeDetector: ChangeDetectorRef,private snack: SnackbarService) {

  }

  ngOnInit() {
    if (this.urlInput) {
      this.showPreview$.next(true);
      this.imageUrl = this.urlInput;
    }
    this.showLoader = (this.noLoader) ? this.noLoader : false;
  }

  ngOnChanges({ urlInput }: SimpleChanges): void {
    if (!urlInput.currentValue) {
        this.showPreview$.next(false);
        this.imageUrl = null;
    }
  }

  fileChanged(target: EventTarget) {
    this.storageS._url.next(null);
    this.storageS.url$
      .pipe(takeUntil(this.subsManager$))
      .subscribe(url => {
        if (url) {
          if (url.preview) {
            this.url.emit({url: url.url, mediaFormat: 'VIDEO', preview: url.preview, fileName: null});
            this.imageUrl = url.preview;
            this.category = 'VIDEO';
            this.showPreview$.next(true);
          }
          else {
            this.storageS.setImageUrl(url.url)
            this.url.emit({url: url.url, mediaFormat: 'PHOTO', preview: null, fileName: url.fileName});
            this.imageUrlSubscription = this.storageS.getImageUrl().subscribe(
              res => {
                this.imageUrl = res;
                this.hasPhoto = !res.includes('/assets/images/camera.svg');
              }
            );
            this.category = 'PHOTO';
            this.showPreview$.next(true);
          }
        } else {
          this.url.emit(null);
          this.imageUrl = null
          this.category = 'PHOTO';
          this.showPreview$.next(false);
        }
        this.isUploading = false;
      },(e)=>{
        console.error(e);
        this.snack.openError('Error uploading the image, try again', 4000);
      });
    this.isUploading = true;
    this.changedImg.emit();
    this.storageS.fileChanged(target, `${this.placeId}${this.imageId}`, this.category, this.requirements);
  }

  ngOnDestroy(): void {
    this.subsManager$.next(true);
    this.subsManager$.complete();
  }

  reset() {
    this.showPreview$.next(false);
    this.imageUrl = null;
    this.storageS.reset()
    this.changeDetector.markForCheck()
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

}
