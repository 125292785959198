// dep
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import jsPDF from "jspdf";
import "jspdf-autotable";


// app
import { ProductsService } from '../../services/products.service';
import { AuthService } from '../../services/auth.service';
import { DomainService } from '../../services/domain.service';
import { Pageable } from '../../constants/pageable';
import { ProductsEditModalComponent } from './products-edit-modal/products-edit-modal.component';



@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls:  ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  public isSuperAdmin = false;
  public paginate: Pageable = { page: 1, size: 10 };
  public dataSource = [];
  public filterField: string;
  public domainValue;
  public loading = true;
  public tableLoading = false;
  public cols = [
    { header: 'Name', field: 'name'},
    { header: 'Description', field: 'description'},
    { header: 'Domain', field: 'domain'},
    { header: 'Price', field: 'price', isCurrency: true},
    { header: 'Map Labs Platform Fee', field: 'platformFee'},
    { header: 'Updated On', field: 'updated'}
  ];
  public domainsOptions = [];
  public domains

  constructor(
    private productsService: ProductsService,
    public dialog: MatDialog,
    private auth: AuthService,
    private domainService: DomainService
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = this.auth.session.isSuperAdmin;

    if (!this.isSuperAdmin) {
      this.cols.splice(1, 1);
    }

    this.getDomains();
  }

  getDomains() {
   this.domainService.getDomains().subscribe(
    res => {
      if (res.domains.length > 0) {
        this.domains =  this.domainService.getOptionsDomains(res.domains);
        // TODO: Every time domains change getProducts() will be executed, which
        // registers a new subscriber handler. There is probably a leak here.
        this.getProducts();
      }
    },
    err => {
      this.tableLoading = false;
      this.loading = false
    });
  }

  getProducts() {
    this.dataSource = [];
    this.productsService.getProducts().subscribe(
      res => {
        const data = res['data'];
        data.forEach(el => {
          if (this.domains.findIndex(i => i == el.domain) != -1) {
            el.updated = new Date(el.updatedAt.$date);
            this.dataSource.push(el);
          }
        });
        this.getOptionsDomains();
        this.tableLoading = false;
        this.loading = false;
      },
      err => {
        this.tableLoading = false;
        this.loading = false;
      }
    );
  }

  getOptionsDomains() {
    const data = {};
    const domains = this.dataSource.map(el => el.domain).filter((indice) => {
      return data.hasOwnProperty(indice) ? false : (data[indice] = true);
    });
    domains.forEach(el => {
      if (el != null && el != undefined) {
        this.domainsOptions.push(el.toUpperCase())
      }
    });
    this.domainsOptions = this.domainsOptions.sort();
  }

  filterDomain(table, value) {
    table.filterGlobal(value, 'contains');
  }

  clearTable(table) {
    this.filterField = null;
    this.domainValue = null;
    table.clear();
  }

  openModal(action, data? : any, table? : any) {
    this.tableLoading = true;
    const dialogRef = this.dialog.open(
      ProductsEditModalComponent,
      {
        width: '800px',
        data: {
          data: data,
          action: action,
          isSuperAdmin: this.isSuperAdmin
        }
      }
    );

    dialogRef.disableClose;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.domainsOptions = [];
        this.getProducts();
      } else {
        this.tableLoading = false;
      }
    });
  }

  exportPdf(table) {
    const data = table.filteredValue || this.dataSource;
    const exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    const doc = new jsPDF('p','pt', [window.innerWidth, window.innerHeight]);
    doc['autoTable'](exportColumns, data);
    doc.save("products.pdf");
  }

  newRow(dt) {
    this.dataSource.push({
      id: null,
      name: null,
      price: null,
      platformFee: null,
      created: null
    });
  }

  pageChange(event) {
    this.paginate = {
      page: event.first,
      size: event.rows
    }
  }

}
