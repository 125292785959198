
<div class="content--full bg--darkblue d-flex justify-content-center align-items-center flex-column" [ngStyle]="{'background-color': (domainService.domain$ | async)?.branding?.primaryColor || ''}">

  <div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="passwordProgress">
    <mat-spinner></mat-spinner>
  </div>

  <div class="logo mb--20 mt--20">
    <a mat-button>
      <img alt="Logo" src="{{ (domainService.domain$ | async)?.branding?.mainLogo || '/assets/images/logo/blankmap.png' }}"  width="170" height="59"/>
    </a>
  </div>

  <!-- Interim login implementation -->
  <div class="box box--md box--padding-md box--rounded bg--white mb--20">
    <form [formGroup]="forgotForm" (submit)="forgotPassword()">

      <h1 class="txt--xl mb--10 txt--center"><strong>Forgot Password</strong></h1>
      <p class="txt--center">Enter your details below to access your account:</p>

      <!--email field-->
      <mat-form-field hideRequiredMarker class="input-group input-group--override">
        <input matInput placeholder="Email" name="email" type="email" formControlName="email" class="input-group__field input--email" required>
        <mat-error *ngIf="email.invalid && email.touched"><p>Please enter valid email</p></mat-error>
      </mat-form-field>

      <!--log in button-->
      <button mat-raised-button color="primary" class="btn btn--xxl btn--full mb--20" [disabled]="forgotForm.invalid"> <strong>Remember password</strong> </button>
    </form>
  </div>

  <div class="box box--md box--padding-xxs box--rounded bg--darkerblue txt--center mb--20">
<!--  todo validate text  -->
    <span class="d-inline-block mr--5 txt--white">Back to Login, </span>
    <a class="txt--lightblue" [routerLink]="['/login']"><u>Sign In</u></a>
  </div>
  <div class="footer footer--noborder flex-column box box--md txt--center">
    <ul class="footer__nav d-flex justify-content-center mb--20">
      <li><a class="txt--white" (click)="goToTermsOfService()">Terms and Conditions</a></li>
      <li><a class="txt--white" (click)="goToPrivacyPolicy()">Privacy Policy</a></li>
      <li><a class="txt--white" (click)="goToPaymentPolicy()">Payment Policy</a></li>
    </ul>
    <p class="txt--normal txt--gray m--0">&copy; {{ env?.branding?.company_name }} All Rights Reserved.</p>
  </div>

</div>


