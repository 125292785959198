<div class="users">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h2 class="m--0"><strong>Users</strong></h2>
  </div>

  <app-skeletons [visibleSection]="'users'" *ngIf="loading"></app-skeletons>

  <div class="users-table" *ngIf="!loading">
    <p-table #dt [value]="dataSource" [columns]="cols" styleClass="p-datatable" [rowHover]="true"
    [responsive]="true"
    [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
    currentPageReportTemplate="{{dt.filteredValue?.length || dataSource?.length}} entries" (onPage)="pageChange($event)"
    [filterDelay]="0" [globalFilterFields]="['email', 'displayName', 'createdOn', 'registrationDomain']">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="input-field--search filter-table">
            <section class="table-actions">
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white"(click)="clearTable(dt)">
                <i class="pi pi-filter-slash"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="dt.exportCSV()">
                <i class="pi pi-file"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf(dt)">
                <i class="pi pi-file-pdf"></i>
              </button>
            </section>
            <section class="table-filter">
              <div class="table__icon table__icon--lg">
                <i class="fas fa-search"></i>
              </div>
            </section>
            <input pInputText type="text" [ngModel]="filterField" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Type to search" />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of cols">
            <th pSortableColumn="{{col?.field}}" [class.th-width-m]="col.field == 'email'">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{col.header | titlecase}}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>
                <p-columnFilter *ngIf="col.field === 'email' && col.field === 'displayName'" type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                <p-columnFilter *ngIf="col.field === 'createdOn'" type="date" field="{{col.field}}" display="menu"></p-columnFilter>
                <p-columnFilter *ngIf="col.field === 'registrationDomain' && domainsOptions.length > 1" field="{{col.field}}" matchMode="in" display="menu">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-dropdown [ngModel]="value" [options]="domainsOptions" placeholder="Domains" (onChange)="filter($event.value)">
                      </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
          </ng-container>
          <th class="txt--right"> Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dataSource>
        <tr class="p-selectable-row">
          <td (click)="selectUser(dataSource)" class="td-width-xm align-left-table"><span [outerHTML]="dataSource?.email | isEmpty"></span></td>
          <td (click)="selectUser(dataSource)" class="td-width-xm"><span [outerHTML]="dataSource?.displayName | titlecase | isEmpty"></span></td>
          <td *ngIf="isSuperAdmin" (click)="selectUser(dataSource)" class="td-width-xm"><span [outerHTML]="dataSource?.registrationDomain | isEmpty"></span></td>
          <td (click)="selectUser(dataSource)"><span [outerHTML]="dataSource?.createdOn | date: 'MM/dd/yy' | isEmpty"></span></td>
          <td class="align-right-table">
            <button mat-flat-button color="primary" class="btn btn-icon" (click)="verifyUserResetPassword(dataSource)" matTooltip="Verify User">
              <i class="far fa-user-check"></i>
            </button>
            <button mat-flat-button color="primary" class="btn btn-icon" (click)="deleteUser(dataSource)" matTooltip="Delete User">
              <i class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No users found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
