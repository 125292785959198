
// dep
import {Injectable} from '@angular/core'
import {AngularFirestore} from "@angular/fire/compat/firestore"
import _ from 'lodash'
// import {Title} from "@angular/platform-browser"

// app
import {DOMAINS} from "../constants/firestore/collections"
import Domain from '../constants/firestore/domain'
// import {environment} from "@environment"

/**
 * Translates a domain like "app.maplabs.com" to an xdomain
 * like "app_maplabs_com". Removes the port number if present.
 */
function domainToXDomain(domain : string) : string {
  return domain.split(":")[0].replace(/\./g, '_') // traslates to "xdomain"
}


/**
 * This service manages the White Label properties
 * TODO: Refactor against domain.service.ts ?
 */
@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {
  constructor(
    private afs: AngularFirestore,
    // private titleService: Title
    ) { }


  // TODO: "async" getter??? bad
  get branding() {
    return (async () => (await this.getDynamicWhiteLabel()).branding)()
  }

  // TODO: This is temporary until we implement fetch from DB
  // => TODO: Remove
  // get apiUrl(): string {
  //   return environment.apiUrl
  // }

  private async fetchDomainDoc(xdomain : string) {
    return (await this.afs.collection(DOMAINS).doc<Domain>(xdomain).get().toPromise()).data()
  }

  async getDynamicWhiteLabel() {
    return await this.fetchDomainDoc(this.slugDomain)
  }

  /**
   * Returns the White Label Stripe Account ID 
   * @returns 
   */
  async getStripeConnectAccountId(domain? : string) : Promise<string | null> {
    return (await this.fetchDomainDoc(domain ? domainToXDomain(domain) : this.slugDomain))?.stripeConnect?.stripeAccountId || null
  }

  get baseDomain(): string {
    return _.startsWith(window.location.hostname, 'localhost') ? 'localhost' : sessionStorage.domain
  }

  get slugDomain(): string {
    return domainToXDomain(this.baseDomain || '')
  }

  get domainUser() : string {
    return `${sessionStorage.domain}`
  }

  get domain() : string {
    const l = window.location
    return `${l.hostname}:${l.port}`
  }

  async getStripePublicKey(xdomain = this.slugDomain) {
    return (await this.fetchDomainDoc(xdomain)).stripe_keys.public_key
  }

  async getStripeTestMaplabsPublicKey() {
    return await this.getStripePublicKey('localhost')
  }

  async getStripeMaplabsPublicKey() {
    return await this.getStripePublicKey('app_maplabs_com')
  }

// TODO: Unused, remove?
//   async setTitle(title = null) {
//     environment.branding = (await this.getDynamicWhiteLabel()).branding
//   }
// 
//   getTitle() {
//     return this.titleService.getTitle();
//   }

}
