<div class="domains">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h2 class="m--0"><strong>Domains</strong></h2>
  </div>

  <app-skeletons [visibleSection]="'domains'" *ngIf="loading"></app-skeletons>

  <div class="domains-table" *ngIf="!loading">
    <p-table #dt [value]="dataSource" [columns]="cols" styleClass="p-datatable" [rowHover]="true" [responsive]="true"
    [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
    currentPageReportTemplate="{{dt.filteredValue?.length || dataSource?.length}} entries"
    [filterDelay]="0" [globalFilterFields]="['domain', 'ultimate', 'basic', 'essential']">
      <ng-template pTemplate="caption">
        <div class="table-header">
          <div class="input-field--search filter-table">
            <section class="table-actions">
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white"(click)="clearTable(dt)">
                <i class="pi pi-filter-slash"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="dt.exportCSV()">
                <i class="pi pi-file"></i>
              </button>
              <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export Pdf" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf(dt)">
                <i class="pi pi-file-pdf"></i>
              </button>
              <p-dropdown *ngIf="domainsOptions.length > 1" class="domains-filter" [showClear]="true" [(ngModel)]="domainValue" [options]="domainsOptions" placeholder="Domains" (onChange)="filterDomain(dt, $event.value)">
              </p-dropdown>
            </section>
            <input pInputText type="text" [ngModel]="filterField"
              (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Type to search" />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of cols">
            <th pSortableColumn="{{col?.field}}" [class.is-currency]="col.isCurrency">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{col.header | titlecase}}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dataSource>
        <tr class="p-selectable-row">
          <td class="td-width-l"><span [outerHTML]="dataSource?.domain | uppercase | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.essential | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.basic | isEmpty"></span></td>
          <td><span [outerHTML]="dataSource?.ultimate | isEmpty"></span></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No domain found.</td>
        </tr>
      </ng-template>
    </p-table>
</div>
