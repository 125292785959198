// dep
import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference } from '@angular/fire/compat/firestore';
import { MatPaginator } from '@angular/material/paginator';
import firebase from 'firebase/compat/app';
import _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http'

// app
import User from '../constants/firestore/user';
import { WhiteLabelService } from './white-label.service';
import { FirestoreService } from './firestore.service';
import { GROUPS, USER } from '../constants/firestore/collections';
import { SESSION, Session } from "../constants/session";
import { Pageable } from '../constants/pageable';
import { environment as ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _users = new BehaviorSubject<User[]>([])
  public loading = false;
  paginator: MatPaginator | any;
  query: any;
  ref: CollectionReference | any;

  private sessionSource: BehaviorSubject<Session>;
  session: any;

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private wl: WhiteLabelService,
    private fs: FirestoreService
    ) {
    const isInIFrame = (window.location !== window.parent.location);
    if (!isInIFrame) {
      const session = localStorage.getItem(SESSION)
      if (!session || session === 'undefined') {
        localStorage.removeItem(SESSION);
        this.sessionSource = new BehaviorSubject<Session>(null);
      } else {
        this.sessionSource = new BehaviorSubject<Session>(JSON.parse(session));
      }
      this.session = this.sessionSource.asObservable();
    }

  }

  updateForLogin(gid: any, uid: any) {
    if (!gid) {
      return;
    }

    return this.getByGidDocId(gid, uid).get().pipe(map(doc => doc.data()),
    switchMap((user) => {
      if (!user) {
        return of(null);
      } else {
        // TODO: Uses browser local clock, use a server one
        user.lastLogin       = firebase.firestore.Timestamp.fromDate(new Date());
        user.lastLoginDomain = this.wl.slugDomain;
        this.altUpdate(user) // TODO: must await finalization of this before continuing?
        return this.getByGidDocId(gid, uid).valueChanges()
      }
    }));
  }

  /**
   * Fetchs user entity from firestore
   * @param docId User.ts
   */
   getByGidDocId(gid: string, docId: string) {
    return this.afs.collection(GROUPS).doc(gid).collection(USER).doc<User>(docId);
  }

  /**
   * Update user entity in firestore
   * @param user User.ts
   */
   async altUpdate(user: User) {
    await this.getByGidDocId(user.gid, user.uid).set(user, { merge: true })
  }

  updateSession(session: Session) {
    this.sessionSource.next(session);
  }

  get(gid: string, uid: string) {
    return this.getByGidDocId(gid, uid).valueChanges()
  }

  getByUID(uid: string) {
    // collectionGroup() searchs for any document who matchs the condition
    // and is stored *at any nesting level* under an USER field. Usually will 
    // find the document under groups.__users
    return this.afs.collectionGroup<User>(USER, ref => ref.where('uid', '==', uid)).get()
  }

  async fetchIsEmailVerified(user: User) {
    const userDoc = (await this.getByGidDocId(user.gid, user.uid).get().toPromise()).data()
    return userDoc.emailVerificationHash == null || userDoc.emailVerified != null
  }

  async updateUser(gid: string, docId: string, data: object) {
    return this.getByGidDocId(gid, docId).update(data)
  }
  
  /**
   * Save User in firestore 
   * @param user User.ts
   */
  async save(user: User) {
    await this.getByGidDocId(user.gid, user.uid).set(user);
  }

  /**
   * Note, this observable must be updated from outside UserService using setUsers,
   * UserService never updates it!
   * TODO: Remove it, bad design. 
   */
  get users(): Observable<User[]> {
    return this._users.asObservable();
  }

  setUsers(users: User[]) {
    this._users.next(users);
  }

  getAllUsers() {
    return this.afs.collectionGroup<User>(USER).valueChanges();
  }

  /**
   * Delete user entity in firestore
   * @param docId  this doc is key in firestore
   */
  async delete(gid : string, docId : string) {
    await this.getByGidDocId(gid, docId).delete();
  }

  getUsersByGroup(gid: string) {
    this.query = this.afs.collection(GROUPS).doc(gid).collection(USER, ref => ref.where('gid', '==', gid)
                 .orderBy('email', 'asc')).ref;

    return this.fs.colWithIds$<User>(USER, ref => this.query);
  }

  getUsers(pageable: Pageable = {
    size: 10,
    page: 1
  }, domain, next, prev, order = 'createdAt', direction = 'desc'): Observable<any> {
    return this.fs.paginateUsers<User>(USER, domain, order, direction, pageable, next, prev);
  }

  getUsersPaginate(count, pageable, actions) {
    return this.fs.formatPagination(count, pageable, actions);
  }

  async fetchImpersonateToken(uid : string) {
    return await this.http.post(`${ENV.billingApiUrl}/user/impersonate_token`, { uid }).toPromise()
  }

}
