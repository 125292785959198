<div class="products-modal">
  <div class="dialog__header txt--center">
    <div class="dialog__close">
      <button mat-icon-button mat-dialog-close tabIndex="-1" class="btn btn-icon btn-icon--txt"><i
        class="fal fa-times"></i></button>
    </div>
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Edit Product</strong></h2>
  </div>
  <div class="dialog__content" mat-dialog-content>
    <div class="dialog-row txt--center" *ngIf="loading">
      <p class="txt--lg txt--center m--0 mb--2"><strong>Wait while we're working in that</strong></p>
      <mat-spinner style="margin:0 auto;"></mat-spinner>
    </div>

    <ng-container *ngIf="!loading">
      <mat-form-field class="col-6">
        <mat-label>Name</mat-label>
        <input matInput type="text" placeholder="Price" [(ngModel)]="productName" required>
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>Price</mat-label>
        <input matInput type="text" placeholder="Price" [(ngModel)]="productPrice" (blur)="formatCurrencyPrice($event.target.value)" required>
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>Map Labs Platform Fee</mat-label>
        <input matInput type="text" placeholder="Platform Fee" [(ngModel)]="productPlatformFee" (blur)="formatCurrencyPlatformFee($event.target.value)" required>
      </mat-form-field>


      <mat-form-field class="col-12">
        <mat-label>Description</mat-label>
        <input matInput type="text" placeholder="Description" [(ngModel)]="productDescription" required>
      </mat-form-field>
    </ng-container>
  </div>
  <div mat-dialog-actions class="dialog__footer justify_end">
    <button mat-flat-button tabIndex="-1" color="accent" (click)="editProduct()" class="btn btn--lg btn--txt--lg">Accept</button>
  </div>
</div>

