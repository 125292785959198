<div class="sidebar" [ngStyle]="{'background-color': (domainService.domain$ | async)?.branding?.secondaryColor || ''}">
	<div class="sidebar__group">
    <a [routerLink]="['settings']" class="sidebar__item" routerLinkActive="active">
      <i class="fas fa-users-cog"></i> White Label Settings
    </a>
    <a *ngIf="isSuperAdmin" [routerLink]="['domains']" class="sidebar__item" routerLinkActive="active">
			<i class="fas fa-list-ol"></i> Domains
		</a>
    <a [routerLink]="['users']" class="sidebar__item" routerLinkActive="active">
			<i class="fas fa-users"></i> Users
		</a>
    <a *ngIf="isSuperAdmin" [routerLink]="['products']" class="sidebar__item" routerLinkActive="active">
			<i class="fas fa-list-ul"></i> Products
		</a>
    <a [routerLink]="['subscriptions']" class="sidebar__item" routerLinkActive="active">
			<i class="fas fa-address-book"></i> Subscriptions
		</a>
    <a [routerLink]="['invoices']" class="sidebar__item" routerLinkActive="active">
      <i class="fas fa-file-invoice-dollar"></i> Invoices
    </a>
    <!-- <a [routerLink]="['upcoming-invoices']" class="sidebar__item" routerLinkActive="active">
			<i class="fa fa-file-import"></i> Upcoming Invoices
		</a> -->
    <a [routerLink]="['view-logs']" class="sidebar__item" routerLinkActive="active">
      <i class="fas fa-eye"></i> View Logs
    </a>
    <a [routerLink]="['grade-leads']" class="sidebar__item" routerLinkActive="active">
      <i class="fas fa-check"></i> Grader Leads
    </a>
	</div>
</div>

